import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Progress,
  Select,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { MdKeyboardArrowDown } from "react-icons/md";
import React, { useEffect, useState } from "react";
import "./filmsPage.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../libs/axiosService";
import LoadingBox from "../../Components/shared/Loading";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { IoSearch } from "react-icons/io5";

const FilmsGrid = ({ category }) => {

  const [loading, setLoading] = useState(true);

  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [originalProjects, setOriginalProjects] = useState([]);
  const toast = useToast();
  const [techInnovation, setTechInnovation] = useState(false);
  const [search, setSearch] = useState("");
  // get query param searchText
	const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchParam = params.get("search");

  useEffect(() => {

    if (searchParam && originalProjects.length > 0) {
      setSearch(searchParam);
      // filter projects based on searchParam from originalProjects state
      setProjects((prev) => {
        return originalProjects.filter((project) => {
          return project.basic.title.toLowerCase().includes(searchParam.toLowerCase());
        });
      });
    }
    else {
      setProjects(originalProjects);
    }

  }, [searchParam, originalProjects]);

  
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/movies-categories");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);



  
  const navigate = useNavigate();
  const navigateToDetails = (id) => {
  
      navigate(`/film/${id}`);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = category
          ? await axiosInstance.get(`/project-lists?category=${category}`)
          : await axiosInstance.get("/project-lists");
        setProjects(response.data.projects);
        setOriginalProjects(response.data.projects);
        setLoading(false);
      } catch (error) {
        toast({
          title: "Error",
          description: error.response?.data || "Failed to fetch projects.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });

        setLoading(false);
      }
    };

    fetchProjects();
  }, [toast, category]);
  

  const [sortBy, setSortBy] = useState("Trending");

  useEffect(() => {
    let sortedProjects = [...originalProjects]; // Create a shallow copy of the original projects
  
    if (sortBy === "Trending") {
      // Reset to the original order (you can define what "Trending" means)
      sortedProjects = originalProjects;
    }
  
    if (sortBy === "Most-Funded") {
      sortedProjects.sort((a, b) => b.fund_raised - a.fund_raised);
    }
  
    if (sortBy === "Lowest-Funded") {
      sortedProjects.sort((a, b) => a.fund_raised - b.fund_raised);
    }
  
    setProjects(sortedProjects); // Update the state with the sorted projects
  }, [sortBy, originalProjects]);

  if (loading) {
    return (
      <>
        <LoadingBox />
      </>
    );
  }


  return (
    <>
      <Box
        backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        h={"250px"}
        w={"full"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={{base:"40px",lg:"96px"}} fontWeight={"500"} color={"white"}>
          Explore it all
        </Text>
      </Box>
      <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
        <Grid templateColumns={{ base: "1fr", lg: "2fr 10fr" }} gap={10}>
          <GridItem bg={""} colSpan={{ base: "1fr" }}>
            <Box textAlign={"start"} p={2} px={"20px"}>
              <Text fontSize={"18px"} fontWeight={""} color={"#1f0733"} mb={"20px"}>
                Filter results
              </Text>
              <Text color={"#1f0733"} fontSize={"14px"} fontWeight={"600"}>
                CATEGORY
              </Text>

              <Flex
                onClick={() => setTechInnovation(!techInnovation)}
                alignItems={"center"}
                mt={"10px"}
                gap={"5px"}
              >
                <Text
                  color={techInnovation ? "orange" : ""}
                  fontSize={"14px"}
                  fontWeight={""}
                >
                 All Categories
                </Text>
                {techInnovation ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}
              </Flex>

              {techInnovation && (
                <Box pl={"20px"}>
                 {
                    categories.map((ele, index) => (
                      <Text
                        key={index}
                        color={"#1f0733"}
                        fontSize={"14px"}
                        fontWeight={""}
                        mt={"10px"}
                        cursor={"pointer"}
                        onClick={() => {
                          navigate(`/films?category=${ele.category}`);
                        }
                        }
                      >
                        {ele.category}
                      </Text>
                    ))
                 }
                </Box>
              )}


           
              <Box h={"2px"} bg={"#DDDDDD"} my={"20px"} />

              <Flex
                mt={"20px"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                  Include closed campaigns
                </Text>
                <Switch colorScheme="orange" size="md" 
                isChecked
                />
              </Flex>
            </Box>
          </GridItem>

          <GridItem bg={""} colSpan={{ base: "1fr" }}>
            <Box bg={""} w={{ base: "100%", lg: "100%" }}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <IoSearch />
                </InputLeftElement>
                <Input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`/view-campaigns?search=${e.target.value}`)
                     }
                  }}
                  placeholder={
                    category ? `Search in ${category}` : "Search for campaigns - press enter"
                  }
                  outline={"none"}
                  border={"1px solid black"}
                />
              </InputGroup>
            </Box>

            <Box h={"2px"} bg={"#DDDDDD"} my={"40px"}></Box>

            <Flex my={"10px"} justifyContent={"end"}>
              <Flex alignItems={"center"} gap={"10px"}>
                <Text fontSize={"18px"} color={"#1f0733"}>Sort by</Text>
                <Select placeholder="Select" w="auto" fontSize={"16px"}
                  onChange={(e) => setSortBy(e.target.value)} value={sortBy}
                >
                  <option style={{color:"black"}} value="Trending">Trending</option>
                  <option style={{color:"black"}} value="Most-Funded">Most Funded</option>
                  <option style={{color:"black"}} value="Lowest-Funded">Lowest Funded</option>
                </Select>
              </Flex>
            </Flex>

            {projects.length > 0 ? (
              <Grid
                templateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
                mt={"20px"}
                gap={"40px"}
              >
                {projects?.map((ele, index) => (
                  <GridItem colSpan={{ base: "1fr" }}>
              
                      <Box
                        textAlign={"start"}
                        w={{ base: "100%", lg: "336px" }}
                        h={"560px"}
                        onClick={() => {
                          navigateToDetails(ele._id);
                        }}
                        bg={""}
                      >
                        <Link to={`/film/${ele._id}`}>
                        <Box
                          h={"312px"}
                          borderRadius={"20px"}
                          position={"relative"}
                          className="relative-box"
                        >
                          <Box
                            w={"100%"}
                            h={"100%"}
                            position={"absolute"}
                            top={0}
                            left={0}
                            className="absolute-box"
                            borderRadius={"20px"}
                          >
                          
                            <Box
                              w={"full"}
                              px={"10px"}
                              className="view-campaign-btn"
                            >
                              <Button
                                w={"full"}
                                size={"lg"}
                                colorScheme="orange"
                              >
                                View Campaign
                              </Button>
                            </Box>
                          </Box>
                          <Image
                            h={"312px"}
                            width={"100%"}
                            objectFit={"cover"}
                            src={
                              process.env.REACT_APP_API_BASE_URL +
                              ele.basic?.project_image
                            }
                            borderRadius={"20px"}
                            />
                        </Box>

                        <Box py={"10px"}>
                          <Text
                            lineHeight={"24px"}
                            fontSize={"18px"}
                            fontWeight={"400"}
                            color={"#1f0733"}
                            >
                            {" "}
                            {ele.basic.title}
                          </Text>
                          <Text
                            fontSize={"14px"}
                            fontWeight={"400"}
                            color={"#1f0733"}
                            mt={"15px"}
                            >
                            STARTING AT
                          </Text>
                          <Text
                            fontSize={"24px"}
                            fontWeight={"500"}
                            color={"#1f0733"}
                            mt={""}
                            >
                            {ele.basic.funding_goal_type}
                            {ele.basic?.funding_goal_amount?.toLocaleString()}
                          </Text>
                          <Progress
                            mt={"15px"}
                            colorScheme="orange"
                            value={ele?.funded_percentage?.toFixed(2)}
                            max={100}
                            className="films-grid-progress"
                            />
                          <Flex mt={"5px"} alignItems={"center"} gap={"15px"}>
                            <Text
                              fontSize={"14px"}
                              fontWeight={""}
                              color={"#1f0733"}
                              >
                               ${ele.fund_raised?.toLocaleString()} raised
                            </Text>
                            <Text
                              fontSize={"14px"}
                              fontWeight={""}
                              color={"#1f0733"}
                              mt={""}
                              >
                              {ele.funded_percentage?.toFixed(2)}% funded
                            </Text>
                          </Flex>
                          <Text
                            fontSize={"14px"}
                            fontWeight={""}
                            color={""}
                            mt={"10px"}
                            >
                               {ele.basic.subcategory}
                          </Text>
                        </Box>
                </Link>
                      </Box>
                
                  </GridItem>
                ))}
              </Grid>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="200px"
                width="100%"
                bg="white.100"
                borderRadius="md"
                m={5}
                boxShadow="md"
              >
                Couldn't find any campaign.
              </Box>
            )}
          </GridItem>
        </Grid>
      </Container>
    </>
  );
};

export default FilmsGrid;
