import { Box, Container, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
const PrivacyPolicy = () => {
  return (
<>

<VStack spacing={"auto"}>
			<Navbar />
				{/* <HomepageComponent /> */}
                <Box
        backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        h={"250px"}
        w={"full"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={{base:"40px",lg:"96px"}} fontWeight={"500"} color={"white"}>
        Privacy Policy
        </Text>
      </Box>
                <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
                {/* <Grid templateColumns={{ base: "1fr", lg: "2fr 10fr" }} gap={10}> */}
                <Box textAlign={"start"}>
                <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Effective Date: October 05, 2024
</Text>
<Text fontSize={"lg"} mt={4}>
  Thanks for visiting GorillaFunder’s Privacy Policy (the “Policy”). We’ve updated our Policy as of August 6, 2024 to reflect certain changes in data privacy laws and our use of personal information.
</Text>
<Text fontSize={"lg"} mt={4}>
  Thanks for using GorillaFunder, and please let us know if you have any questions or feedback.
</Text>
<Text fontSize={"lg"} mt={4}>
  Before you use or submit any personal information to GorillaFunder, please review this Policy carefully.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Definitions
</Text>
<Text fontSize={"lg"} mt={4}>
  (these terms are not always capitalized throughout but these meanings apply)
</Text>
<Text fontSize={"lg"} mt={4}>
  <strong>Backers.</strong> Backers are GorillaFunder’s users and others who pledge to bring projects to life.
</Text>
<Text fontSize={"lg"} mt={4}>
  <strong>Collaborators.</strong> Collaborators are other parties that Creators can appoint to help manage their project, including by providing project marketing, updating project information, and coordinating reward fulfillment. These Collaborators may be able to access personal information available to Creators (including Backers’ names, email addresses, pledge amounts, reward selections, messages, and survey responses), and are required to treat Backers’ personal information with the same care and respect as Creators are.
</Text>
<Text fontSize={"lg"} mt={4}>
  <strong>Creators.</strong> Creators are other parties who run projects on the GorillaFunder platform. Backers pledge to support various Creator projects hoping to get a reward.
</Text>
<Text fontSize={"lg"} mt={4}>
  <strong>Personal information.</strong> Any information that identifies you directly or indirectly in combination with other data about you. Personal information does not include information that is publicly available, de-identified, or aggregated.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Changes to this Policy
</Text>
<Text fontSize={"lg"} mt={4}>
  We may update this Policy from time to time as privacy laws or our practices change. We encourage you to review this Policy periodically. If you keep using GorillaFunder after a change, we will assume you accept the current version of the Policy. This Policy may be translated into another language for your convenience; in the event of a conflict between the English version and any translations, the English version shall control.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Who We Are
</Text>
<Text fontSize={"lg"} mt={4}>
  GorillaFunder, PBC (together with The Creative Independent, LLC, Project Partners, LLC, and their parents, subsidiaries, affiliates, agents, representatives, consultants, employees, officers, and directors—collectively, “gorillafunder,” “we,” or “us”) provides services that help people bring creative projects to life (our “Services”). Our main service is a funding platform for creative projects. We are a Public Benefit Corporation based in New York, New York, United States.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  How this Policy Applies
</Text>
<Text fontSize={"lg"} mt={4}>
  This Policy describes the personal information we collect from you, how we use that personal information, and our reasons for doing so. It also explains when and how personal information may be shared and your rights and choices regarding the personal information you provide to us.
</Text>
<Text fontSize={"lg"} mt={4}>
  This Policy is incorporated into and part of gorillafunder’s Terms of Use.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
  Personal Information We Collect
</Text>
<Text fontSize={"lg"} mt={4}>
  GorillaFunder collects personal information in several ways, primarily:
</Text>
<Text fontSize={"lg"} mt={4}>
  - When Creators, Collaborators, and Backers provide personal information directly to us (such as when setting up an account);
</Text>
<Text fontSize={"lg"} mt={4}>
  - When we passively collect personal information from you (such as from your interaction with our website or mobile app); and
</Text>
<Text fontSize={"lg"} mt={4}>
  - From other parties (such as gorillafunder’s trusted payment provider).
</Text>

<Text fontSize={"lg"} mt={4}>
  To create an account on GorillaFunder and use our Services, we collect and process certain personal information. Depending on your use of our Services, this personal information may include:
</Text>
<Text fontSize={"lg"} mt={4}>
  - Personal information you provide by completing forms on GorillaFunder — for example, your name, email and postal addresses, telephone number, country of residence, login, and password details. We may ask for this information if you register as a user of the Services, subscribe to our newsletters, complete surveys, or submit content to GorillaFunder. You also may choose to log in to GorillaFunder using your existing Apple or Facebook account. When you do this, and in compliance with the permissions you grant, we may be able to access information about you from your Apple or Facebook account, such as the email address or profile picture associated with that account.
</Text>
<Text fontSize={"lg"} mt={4}>
  - Any personal information you include in your communications to GorillaFunder, such as when you ask for support, send us questions or comments, or report a problem with our Services.
</Text>
<Text fontSize={"lg"} mt={4}>
  - Personal information that you submit on or to gorillafunder’s platform, such as comments, contributions to discussions, or messages to other users.
</Text>
<Text fontSize={"lg"} mt={4}>
  - Personal information you provide for identity verification purposes when launching a project, which can include your legal name, date of birth, and business name and Tax ID for business entities. In some cases, our payment processor will request that you provide us with a secure upload of an identity document (such as your passport, driver’s license, or other government-issued ID).
</Text>
<Text fontSize={"lg"} mt={4}>
  - Details of any transactions you make through the Services, such as who you paid, the date of payment, and what reward you were provided. GorillaFunder partners with Stripe for payment processing, and the payment information you submit is collected and used by Stripe in accordance with their privacy practices (read Stripe’s Privacy Policy). GorillaFunder only stores the last four digits of your credit card or bank account (as applicable), expiration date, and country, which we require for tax, regulatory, and security purposes. We do not store full payment card or bank account details.
</Text>
<Text fontSize={"lg"} mt={4}>
  - Information about your interactions with GorillaFunder, including use of our website and mobile app, such as your IP address, approximate location information, your device identifier, the type of device or browser you use, and your actions during your visit, including how you navigate our website or mobile app. For more information about our use of cookies and other tracking technologies, see our Cookie Policy.
</Text>
<Text fontSize={"lg"} mt={4}>
  - When you use our mobile app, depending on the settings you select, we may be able to collect your mobile device identifier and information about your approximate location and network connections, receive usage data from your device, run at device startup, and prevent your device from going to sleep.
</Text>
<Text fontSize={"lg"} mt={4}>
  - Demographic information, such as your age, gender identity, sexual orientation, race, and ethnicity, when you choose to provide this information to help GorillaFunder equitably support all Creators and represent a diverse population in our research. Typically, this information is only processed when you voluntarily choose to provide it to us. For example, GorillaFunder sends optional Creator surveys that collect Creator demographics, including things like gender identity and sexual orientation. The results of these demographic surveys are used by GorillaFunder to enhance the Creator experience. The demographic information we collect includes personal information that may qualify as sensitive personal information under various privacy laws.
</Text>
<Text fontSize={"lg"} mt={4}>
  You may decline to provide us with your personal information. However, this can limit your ability to register for an account, create or back a project, or otherwise use our Services. You may review, update, or delete your account through your account settings.
</Text>









<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Cookies and Other Tracking Technologies
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder collects data about website use through our own tracking technologies and tracking technologies provided by our vendors, such as those that help us with data analytics, marketing GorillaFunder projects, and content delivery. Some of this data may be considered personal information.
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder commonly uses cookies and pixels. Cookies are small text files that may be saved by your internet browser when you visit a website. These text files then store information about your use of gorillafunder’s Services that can be retrieved from your device. Pixels are snippets of code or tiny invisible images embedded in email and websites that help us learn how you interact with the content (for example, if you clicked on a link or an image).
</Text>
<Text fontSize={"lg"} mt={4}>
    Some tracking technology use is necessary for website functionality. These tracking technologies can also support website security, analytics, and an improved overall user experience. You may be able to use your browser settings to block tracking technologies, but blocking tracking technologies necessary for our website functionality may prevent you from using our Services.
</Text>
<Text fontSize={"lg"} mt={4}>
    Please see gorillafunder’s Cookie Policy for more information about our tracking technology use, including your choices regarding our use of tracking technologies.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    How We Use Personal Information
</Text>
<Text fontSize={"lg"} mt={4}>
    We use the personal information we collect for various purposes, including to:
</Text>
<Text fontSize={"lg"} mt={4}>
    - register and service your account with us;
</Text>
<Text fontSize={"lg"} mt={4}>
    - send you transactional communications about your projects or pledges;
</Text>
<Text fontSize={"lg"} mt={4}>
    - provide customer service and respond to your requests for information;
</Text>
<Text fontSize={"lg"} mt={4}>
    - show you projects that may be of interest to you;
</Text>
<Text fontSize={"lg"} mt={4}>
    - send you marketing or promotional communications;
</Text>
<Text fontSize={"lg"} mt={4}>
    - analyze the use and functionality of our website and app;
</Text>
<Text fontSize={"lg"} mt={4}>
    - improve and develop the quality of your online experience, including through personalization;
</Text>
<Text fontSize={"lg"} mt={4}>
    - enhance the effectiveness of our Services;
</Text>
<Text fontSize={"lg"} mt={4}>
    - conduct research and analysis;
</Text>
<Text fontSize={"lg"} mt={4}>
    - effectively manage our business;
</Text>
<Text fontSize={"lg"} mt={4}>
    - prevent fraud and abuse; and
</Text>
<Text fontSize={"lg"} mt={4}>
    - monitor and improve the security of our Services.
</Text>
<Text fontSize={"lg"} mt={4}>
    We also use personal information we collect as reasonably necessary for our legal compliance, to maintain appropriate records for administrative purposes, to protect our rights and interests or those of others, to resolve disputes, to enforce our policies, to prevent harm, and for any other purposes permitted by applicable laws or for which you provide your consent.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    How We Share Personal Information
</Text>
<Text fontSize={"lg"} mt={4}>
    There are certain circumstances in which GorillaFunder may share your personal information with others. GorillaFunder will seek to share the minimum amount of personal information necessary when your personal information is shared with others. The usual circumstances in which GorillaFunder may share your personal information are as follows:
</Text>
<Text fontSize={"lg"} mt={4}>
    - GorillaFunder may share your personal information with any other party based on your consent or instruction to do so.
</Text>
<Text fontSize={"lg"} mt={4}>
    - We may share your personal information with the vendors who perform services on our behalf, including recruiting, marketing, customer support, legal services, data storage, data analysis, and helping us detect and protect against fraud, illegal activities, malicious behaviors, security threats, and other misuses of our Services.
</Text>
<Text fontSize={"lg"} mt={4}>
    - We may share your personal information with current and future affiliated companies, including as part of a business transaction, such as corporate divestiture, merger, consolidation, acquisition, reorganization, or other disposition of all or any portion of our business or our affiliates, including in connection with a bankruptcy or similar proceeding.
</Text>
<Text fontSize={"lg"} mt={4}>
    - We may use and disclose any personal information as reasonably necessary to comply with law and legal requirements, cooperate with and respond to law enforcement requests, exercise or defend our legal rights or those of others, or as otherwise required by applicable law, court order, or governmental regulations. 
</Text>
<Text fontSize={"lg"} mt={4}>
    - Any personal information we collect may be provided to our professional advisors, including accountants, auditors, consultants, and lawyers, when reasonably necessary for our professional advisors to perform services for us and give us appropriate advice.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Personal Information Shared Publicly on GorillaFunder
</Text>
<Text fontSize={"lg"} mt={4}>
    When you create an account, we create a basic profile page for you on GorillaFunder, containing your username, the date the account was created, and the number of projects you have backed or launched. By default, your profile is set to private and will only publicly display your username and avatar, date your account was established, and number of projects backed or launched. You can choose to make it public in your account settings. Your username appears, for example, with the comments you post and messages you send on GorillaFunder and, if your profile is set to public, people can click your username to view more information about you. If you make your profile public, others will be able to see the following things:
</Text>
<Text fontSize={"lg"} mt={4}>
    - the account username you select, and the date the account was created;
</Text>
<Text fontSize={"lg"} mt={4}>
    - any personal information you choose to add to your profile (such as your avatar, bio, location, time zone, or website);
</Text>
<Text fontSize={"lg"} mt={4}>
    - projects you’ve backed (but not pledge amounts or rewards chosen);
</Text>
<Text fontSize={"lg"} mt={4}>
    - projects you’ve created;
</Text>
<Text fontSize={"lg"} mt={4}>
    - comments you’ve posted on GorillaFunder;
</Text>
<Text fontSize={"lg"} mt={4}>
    - if you have “Liked” a project update; and
</Text>
<Text fontSize={"lg"} mt={4}>
    - other fields that we may add from time to time.
</Text>
<Text fontSize={"lg"} mt={4}>
    If you are a Backer and don’t want others to see your real name or image on a project’s Community tab, you may choose an avatar or name that is not identifiable (for example, CatLuvr88 or an image of a cat). You can also modify or delete certain personal information associated with your account (such as your username, avatar, bio, and location) through your account settings, where you can also change your privacy settings. You may delete your entire account by submitting a privacy request via legal.gorillafunder.com.
</Text>
<Text fontSize={"lg"} mt={4}>
    Creators are asked to verify their identities before launching a project. Once this has been done, the Creator’s Verified Name will be publicly displayed on their account profile and on any projects they launch. Collaborators are not verified, and are instead invited by Creators and sign up for a GorillaFunder account (more information on Collaborators can be found here).
</Text>








<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Personal Information Shared with Creators and Collaborators
</Text>
<Text fontSize={"lg"} mt={4}>
    Creators and Collaborators are central to gorillafunder’s Services as they provide the projects that drive our mission. Given that responsibility, Creators and Collaborators are bound by this Policy and may only use personal information they receive from GorillaFunder for fulfillment purposes. When “Creator” is used in this section, we are also referring to their Collaborators.
</Text>
<Text fontSize={"lg"} mt={4}>
    When a Backer pledges to back a project, the Creator will receive personal information about the Backer, including the Backer’s name and amount of pledge. If the project is successful, Creators receive additional Backer personal information, such as an email address and other profile details. This allows Creators to send out a survey directly requesting any information necessary to provide rewards the Backer requests, such as a mailing address or t-shirt size.
</Text>
<Text fontSize={"lg"} mt={4}>
    Creators are required to keep Backers’ personal information confidential and may only use Backers’ personal information they get from GorillaFunder as strictly necessary to communicate with Backers directly and provide rewards. Creators may not sell any personal information or use it for targeted advertising purposes.
</Text>
<Text fontSize={"lg"} mt={4}>
    Creators never receive Backers’ credit card details or other payment information. Creators also should not ask for personal information that isn’t necessary to provide your reward and should never request sensitive personal information, such as an identification number or payment information. Contact us at support@gorillafunder.com if you receive a request for personal information that seems inappropriate or excessive.
</Text>
<Text fontSize={"lg"} mt={4}>
    Collaborators are other parties that Creators can appoint to help manage their project. These Collaborators may be able to access the personal information made available to Creators and are similarly bound by this Privacy Policy and required to treat Backers’ personal information with the same care and respect as Creators are. Any Creator that chooses to add a Collaborator to a project must only do so in a way that complies with all other GorillaFunder personal information sharing and privacy practices. Regardless of whether the Collaborator is one person or a fulfillment company, Collaborators are prohibited from using Backer personal information provided to them by Creators for any purpose other than fulfillment and in compliance with this Privacy Policy.
</Text>
<Text fontSize={"lg"} mt={4}>
    Creators and Collaborators may also receive routine traffic analytics about their project pages and project marketing.
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder reserves the right to take disciplinary action(s), including suspension, for any Creator or Collaborator found to be in violation of any requirement or limitation set out in this Policy.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Links to Other Websites and Services
</Text>
<Text fontSize={"lg"} mt={4}>
    gorillafunder’s website and app may include links to other websites or services whose privacy practices may differ from ours. When you follow a link to a website or service off of the GorillaFunder platform, the privacy policy and other disclosures for that website or service will govern their processing of your personal information.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Children’s Personal Information
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder does not knowingly collect personal information from people under the age of 16. People under the age of 16 are not permitted to register for a GorillaFunder account or pledge to back projects. You must be at least 18 years old to run a project as a Creator or to be appointed as a Collaborator.
</Text>
<Text fontSize={"lg"} mt={4}>
    If you believe that someone under the age of 16 has provided us with personal information or that someone under 18 is a Creator or Collaborator on a GorillaFunder project, please contact us at dpo@gorillafunder.com. If we learn that we have received personal information from someone under the age of 16, we will delete such personal information in accordance with applicable law. We will take appropriate actions to remove projects run by people under the age of 18.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Privacy Rights and Choices
</Text>
<Text fontSize={"lg"} mt={4}>
    If at any time, you believe that your personal information has been used in violation of this Policy, you may report your concerns to us by emailing dpo@gorillafunder.com.
</Text>
<Text fontSize={"lg"} mt={4}>
    User Account Settings. All GorillaFunder users can manage personal information in their account settings. From your account, you can request a downloadable copy of your personal information from GorillaFunder. You may also do so directly by visiting legal.gorillafunder.com. To correct or update the personal information you’ve provided to us, please log in and update your profile information. You may also delete your user account from within your account settings, or by visiting legal.gorillafunder.com. We may retain personal information when required by law or as necessary for our business following an account deletion request.
</Text>
<Text fontSize={"lg"} mt={4}>
    Privacy Rights under Certain Laws. Users in certain locations, such as the European Economic Area, United Kingdom, and California, are given certain privacy rights regarding their personal information. These rights include the right to know what personal information we process about you and to request access to, correction of, and deletion of your personal information. You also may have the right to ask for a portable copy of your personal information or to object to or ask that we restrict our processing of your personal information. If we rely on your consent for personal information processing, you may withdraw your consent at any time. GorillaFunder will not discriminate against any user for exercising privacy rights provided by applicable law.
</Text>
<Text fontSize={"lg"} mt={4}>
    If you would like further information regarding your legal rights under applicable data privacy law or would like to exercise any of them that are relevant to you, contact us at dpo@gorillafunder.com. In your email, please include your full name, GorillaFunder username, zip code or postal code, the email address at which you prefer to be contacted and, if different, the email address associated with your GorillaFunder account, and the privacy right(s) you are requesting, such as the deletion of information related to a particular transaction or the correction of specific information about you. Please monitor your incoming email correspondence in case we have follow-up questions regarding your privacy request.
</Text>
<Text fontSize={"lg"} mt={4}>
    Certain laws allow an authorized agent acting on your behalf to make privacy requests, which they may do by following the instructions above. If an authorized agent submits a request for you, we may ask you to verify your own identity or confirm the request directly with us.
</Text>
<Text fontSize={"lg"} mt={4}>
    Subject to appropriate verification of your identity, which may include asking you for additional information to match to information we have, GorillaFunder will honor the privacy rights provided to you by applicable law (such as the European Union’s General Data Protection Regulation if you are located in the European Economic Area). You can expect that GorillaFunder will respond to your privacy request within the required time period under applicable law, unless we reach out to explain that it will take us longer. If we cannot fulfill your privacy request in whole or in part, we will explain why. It is our policy not to disclose any information in response to a privacy request that may adversely affect the privacy of other persons unless legally required to do so.
</Text>
<Text fontSize={"lg"} mt={4}>
    If you have any questions about our response to your privacy request, please contact us at dpo@gorillafunder.com. While we will always try to assist when you have concerns about the privacy of your personal information and appreciate an opportunity to do so, if you have concerns about how we manage your personal information or respond to your requests, you may be able to file a complaint with your local data protection authority or privacy regulator.
</Text>
<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Our Communications
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder wants to communicate with you only if you want to hear from us. We try to keep emails to a minimum, and you can unsubscribe from marketing or promotional communications at any time by adjusting your notification settings or by selecting the “unsubscribe” link in any communication you receive. If you download the GorillaFunder app, you can manage the notifications you receive on your mobile device in your profile settings. Additionally, to improve our services we may contact you for market research purposes. You always have the choice about whether to take part in our market research.
</Text>
<Text fontSize={"lg"} mt={4}>
    Even if you unsubscribe from marketing communications, GorillaFunder and our Creators and their Collaborators will still be able to send you communications relating to your transactions and rewards, such as successful projects you have backed. This helps ensure they can provide you with updates on your rewards. We’ll also be able to send you service-related announcements when it’s necessary to do so.
</Text>






<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Personal Information Retention
</Text>
<Text fontSize={"lg"} mt={4}>
    In general, we keep personal information as necessary to provide you with our Services. We will typically retain your personal information as long as your account is active and for a reasonable period of time afterward. We will retain and use personal information as permitted by law, as necessary for any purposes explained in this Policy and any reasonably compatible purposes, and to the extent necessary to comply with our legal obligations, resolve disputes, enforce our agreements, and protect gorillafunder’s legal rights and those of our users. Where your personal information is used for more than one purpose, we will retain it until the last retention period is met.
</Text>
<Text fontSize={"lg"} mt={4}>
    In determining our retention periods, we consider factors such as our legal and contractual obligations, as well as the amount, nature, and sensitivity of the personal information, the purposes for which we process the personal information, and the potential risk of harm that may result from unauthorized processing of the personal information.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Personal Information Security
</Text>
<Text fontSize={"lg"} mt={4}>
    The security of your personal data is important to us. We look to good industry practices to protect the data we collect and maintain, including using Transport Layer Security (TLS) to encrypt information as it travels over the internet. No method of transmission over the internet or electronic storage is completely secure, so GorillaFunder cannot guarantee the security of your personal information. Your account information is protected by a password, which you should choose carefully and keep secure. We encourage all users to enable two-factor authentication in account settings to further protect their account. We also encourage the responsible disclosure of vulnerabilities of our Services by emailing security@gorillafunder.com, and we will invite valid disclosures to our bug bounty program.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Notice to Our Users from the United States
</Text>
<Text fontSize={"lg"} mt={4}>
    Over the past several years, more than a dozen states have passed privacy laws to protect consumers in those states. We recognize the privacy rights of consumers residing in any state with an active consumer privacy law that is applicable to GorillaFunder. If you are a resident of a state with an active consumer privacy law, and you want to ask for additional information about your rights, you may contact us at dpo@gorillafunder.com.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Sales of Personal Information and Targeted Advertising
</Text>
<Text fontSize={"lg"} mt={4}>
    State privacy laws may permit consumers to opt out of the sale of personal information, including when that personal information is provided to others in exchange for non-monetary value or to facilitate targeted advertising. When GorillaFunder permits others to collect Personal Information about you on our website or mobile app, we take steps to limit their use of your Personal Information. We recommend that you review our Cookie Policy to find out about additional steps you can take to limit the collection of Personal Information through tracking technologies. If you would like us to remove your Personal Information from use in our marketing efforts, please contact us at dpo@gorillafunder.com.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Additional Information for California Residents
</Text>
<Text fontSize={"lg"} mt={4}>
    This information supplements other parts of this Policy and provides additional information to California residents in accordance with the requirements of the California Consumer Privacy Act. Here we provide additional information about our personal information processing practices in the previous 12 months and our current personal information collection practices. Please read this information in conjunction with the other portions of our Policy. For instance, we have described the personal information we collect, the sources from which we collect personal information, and our purposes for that collection at the beginning of this Policy. We have also explained how we disclose personal information to others and how you can exercise your privacy rights in other portions of our Policy.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    California Personal Information We Collect
</Text>
<Text fontSize={"lg"} mt={4}>
    Throughout this Policy, we discuss the specific pieces of personal information we collect from and about our users. Note that the personal information we collect about you may vary depending on the nature of your interactions with us and may include all or some of the examples listed below or additional types of personal information in the categories we identify:
</Text>
<Text fontSize={"lg"} mt={4}>
    - Identifiers and Non-Public Personal Information, such as your full name, contact information, account username, IP address, or government-issued identification.
</Text>
<Text fontSize={"lg"} mt={4}>
    - Commercial Information, such as the history of projects you created or backed and rewards obtained from successful projects.
</Text>
<Text fontSize={"lg"} mt={4}>
    - Inferences, such as information about your project preferences and projects you may be likely to create or back.
</Text>
<Text fontSize={"lg"} mt={4}>
    - Internet Activity Information, such as what you search for on our website or app and other information about how you interact with our website or app.
</Text>
<Text fontSize={"lg"} mt={4}>
    - Geolocation, including approximate location inferred from your IP address.
</Text>
<Text fontSize={"lg"} mt={4}>
    - Audio and Visual Information, such as recorded customer service interactions and photos or videos that you might submit.
</Text>
<Text fontSize={"lg"} mt={4}>
    - Professional Information, such as a Creator’s business name and business tax identification number.
</Text>
<Text fontSize={"lg"} mt={4}>
    - Protected Characteristics, when you choose to provide them, such as race or ethnicity, gender or gender identity, sexual orientation, or age (if you are over 40).
</Text>
<Text fontSize={"lg"} mt={4}>
    We may disclose any of these categories of personal information to our service providers, Creators, and others as described in the “How We Share Personal Information” and “Personal Information Shared with Creators and Collaborators” sections above.
</Text>

<Text fontSize={"lg"} mt={4}>
    GorillaFunder may process sensitive personal information such as driver’s license number, passport number, information about racial or ethnic origin, information about sexual orientation, and contents of messages between users of GorillaFunder. We do not use or disclose sensitive personal information for purposes that would give rise to the right to limit the use and disclosure of this data.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    California Privacy Rights
</Text>
<Text fontSize={"lg"} mt={4}>
    In addition to the rights described in the “Privacy Rights and Choices” section above, California residents may ask us for information about our processing of their personal information, including the categories and sources of personal information, our purposes for processing personal information, and how we disclose personal information to others.
</Text>
<Text fontSize={"lg"} mt={4}>
    You, or an authorized agent acting on your behalf, may submit any California privacy rights requests to us by emailing dpo@gorillafunder.com. Please include your full name, GorillaFunder username, zip code, the email address at which you prefer to be contacted and, if different, the email address associated with your GorillaFunder account, and the privacy right(s) you are requesting.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    California Notice of Financial Incentives
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder may choose to offer financial incentives to our users from time to time. If we offer financial incentives to California residents that involve certain uses of personal information, we will provide you with a separate notice regarding the financial incentive when we offer it. This notice will describe the financial incentive offered, including any material terms, how you can participate or withdraw from participation, and information about how any price or service difference offered to you is related to the value of your personal information processed as part of the incentive.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    California Shine the Light Law
</Text>
<Text fontSize={"lg"} mt={4}>
    California’s “Shine the Light” law gives Californians the right to request information from us about how we share personal information with other parties for their direct marketing purposes. GorillaFunder does not share your personal information for this purpose.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Notice to Our International Users
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder, PBC is the data controller responsible for personal information we collect through our website and app. Our Data Protection Officer can be contacted at dpo@gorillafunder.com.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    GDPR Data Protection Representative
</Text>
<Text fontSize={"lg"} mt={4}>
    To exercise your rights under this Privacy Policy, or if you have questions about this policy, contact us at dpo@gorillafunder.com. This email address is valid for all inquiries including GorillaFunder's designated data protection representatives in the EU, EEA, and UK. If you wish to contact our data protection representatives other than by email, you may do so at the following addresses:
</Text>

<Text fontSize={"lg"} mt={4}>
    - EU Representative: GorillaFunder, PBC, Attn: Data Protection Officer, St. Michielskaai 3, 9000 Ghent, Belgium
</Text>
<Text fontSize={"lg"} mt={4}>
    - UK Representative: GorillaFunder, PBC, Attn: Data Protection Officer, 27–28 Hammersmith Grove, London, W6 7HA, United Kingdom
</Text>
<Text fontSize={"lg"} mt={4}>
    - EEA Representative: GorillaFunder, PBC, Attn: Data Protection Officer, 20-22 Wenlock Road, London, N1 7GU, United Kingdom
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Changes to This Policy
</Text>
<Text fontSize={"lg"} mt={4}>
    We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will provide you with notice of any material changes by posting the new Privacy Policy on our website and updating the effective date at the top of this Privacy Policy.
</Text>

<Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
    Contact Us
</Text>
<Text fontSize={"lg"} mt={4}>
    If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at dpo@gorillafunder.com or at:
</Text>
<Text fontSize={"lg"} mt={4}>
    GorillaFunder, 1112 Van Buren Ave, Oxford, MS 38655
</Text>




                </Box>
                </Container>
                </VStack>
                <Footer/>
                
</>
  )
}

export default PrivacyPolicy