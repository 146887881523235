import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import Footer from "../../../Components/Footer";
import { Box, Button, Container, Divider, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { IoEyeSharp, IoStatsChart } from "react-icons/io5";
import FluidContainer from "../../../Components/shared/containerFluid";
import { FaHeart, FaTimes } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { FaLongArrowAltRight } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import axiosInstance from "../../../libs/axiosService";
import useGetUser from "../../../libs/useGetUser";

const Project = () => {
  const [terms, setTerms] = useState(true);
  const [filmDetails, setFilmDetails] = useState({});
  const { user } = useGetUser();
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen: isTrashOpen, onOpen: onTrashOpen, onClose: onTrashClose } = useDisclosure();


  useEffect(() => {
    const fetchFilmDetails = async () => {
      try {
        const response = await axiosInstance.get("/project-filter-details/" + id);
        setFilmDetails(response.data);
        if (response.data?.people?.user?._id !== user._id) {
          navigate("/");
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.response?.data || "Failed to fetch film details.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    fetchFilmDetails();
  }, [toast, id]);

  const onTabClick = (tab) => {
    navigate(`/edit/${id}?tab=${tab}`);
  }

  const submitTrashAnswer = async () => {
    try {
      const response = await axiosInstance.delete(`/project/${id}`);
      toast({
        title: "Success",
        description: "Project deleted successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/view-profile?tab=Campaigns");
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data || "Failed to delete project.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

  }

  return (
    <>
      <Navbar logoLevel="2" />
      <FluidContainer>
        <Box>
          <Text mt={"80px"} fontSize={"35px"} textAlign={"start"}>
            {filmDetails?.basic?.subcategory} Project
          </Text>
          <Text fontSize={"15px"} mt={"10px"} textAlign={"start"}>
            by {filmDetails?.people?.user?.name}
          </Text>
          <Box textAlign={"start"} my={"30px"}>
            {filmDetails?.published && <Link to={"/film/" + id}>
              <Button
                colorScheme="orange"
                fontSize={"14px"}
                fontWeight={500}

              >
                <IoEyeSharp style={{ marginRight: "10px" }} />
                Preview
              </Button>
            </Link>}
            {filmDetails?.published &&
              <Link to={"/project-stats/" + id}>
                <Button
                  colorScheme="green"
                  fontSize={"14px"}
                  fontWeight={500}
                  ml={5}
                >
                  <IoStatsChart style={{ marginRight: "10px" }} />
                  Project Stats
                </Button>
              </Link>}
            <Link to={"/edit/" + id}>
              <Button
                colorScheme="gray"
                fontSize={"14px"}
                fontWeight={500}
                ml={filmDetails?.published ? 5 : 0}
              >
                <MdEdit style={{ marginRight: "10px" }} />
                Edit Project
              </Button>
            </Link>
          </Box>
        </Box>

        <Text fontSize={"23px"} textAlign={"start"}>
          Project Status
        </Text>

        <Flex
          mt={"25px"}
          mb={"20px"}
          px={"20px"}
          py={"30px"}
          alignItems={"center"}
          cursor={"pointer"}
          onClick={() =>
            filmDetails?.published ? navigate(`/project-stats/${id}`) : navigate(`/edit/${id}`)
          }
          gap={"20px"}
          border={"1px solid rgba(0,0,0,0.2)"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            w={"40px"}
            height={"40px"}
            borderRadius={"50%"}
            background={
              filmDetails?.published ? "green.200" : "red.200"
            }
            border={filmDetails?.published ? "5px solid #45a345" : "5px solid #e53e3e"}
            p={"5px"}
          >
            {
              filmDetails?.published ? <FaCheck /> : <FaTimes />
            }
          </Flex>
          <Box textAlign={"start"}>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              {
                filmDetails?.published ? "Published" : "Unpublished"
              }
            </Text>
            <Text fontSize={""} color={"gray.500"}>
              Your project is currently {
                filmDetails?.published ? "live." : "not live yet."
              }
            </Text>
            {filmDetails?.published ?
              <Text fontSize={"13px"} mt={"5px"} fontWeight={600} textDecoration={"underline"}
                cursor={"pointer"}
                color={"green"}>
                Please click here to view your project stats.
              </Text> :
              <Text fontSize={"13px"} mt={"5px"} textDecoration={"underline"}
                cursor={"pointer"}
                color={"blue"}
                onClick={() => navigate(`/edit/${id}`)}
              >
                Please fill all the details to publish your project.
              </Text>
            }
          </Box>
        </Flex>


        <Text fontSize={"23px"} textAlign={"start"}>
          Project Terms
        </Text>
        {/* gray box */}
        {
          terms ?
            <Box
              bg={"gray.50"}
              border={"1px solid rgba(0,0,0,0.2)"}
              mt={"20px"}
              py={"80px"}
              width={"100%"}
            >
              <Container>
                <Flex mb={"30px"} justifyContent={"center"}>
                  <FaHeart size={"50px"} color="orange" />
                </Flex>
                <Text fontSize={"25px"} fontWeight={"500"}>
                  Take a moment to review our rules
                </Text>

                <Text
                  fontSize={"17px"}
                  mt={"20px"}
                  fontWeight={""}
                  textAlign={"center"}
                >
                  Here are five rules every GorillaFunder project must follow.
                </Text>

                <Flex
                  alignItems={"center"}
                  mt={"40px"}
                  justifyContent={""}
                  gap={"40px"}
                >
                  <Text color={"gray.300"} fontSize={"40px"}>
                    1
                  </Text>
                  <Text fontSize={"17px"} fontWeight={""}>
                    Projects must create something to share with others.
                  </Text>
                </Flex>

                <Flex
                  alignItems={"center"}
                  mt={"40px"}
                  justifyContent={""}
                  gap={"40px"}
                >
                  <Text color={"gray.300"} fontSize={"40px"}>
                    2
                  </Text>
                  <Text fontSize={"17px"} fontWeight={""} textAlign={"start"}>
                    Projects must be honest and clearly presented.
                  </Text>
                </Flex>
                <Flex
                  alignItems={"center"}
                  mt={"40px"}
                  justifyContent={""}
                  gap={"40px"}
                >
                  <Text color={"gray.300"} fontSize={"40px"}>
                    3
                  </Text>
                  <Text fontSize={"17px"} fontWeight={""} textAlign={"start"}>
                    Projects can't fundraise for charity.
                  </Text>
                </Flex>
                <Flex
                  alignItems={"center"}
                  mt={"40px"}
                  justifyContent={""}
                  gap={"40px"}
                >
                  <Text color={"gray.300"} fontSize={"40px"}>
                    4
                  </Text>
                  <Text fontSize={"17px"} fontWeight={""} textAlign={"start"}>
                    Projects can't offer equity.
                  </Text>
                </Flex>
                <Flex
                  alignItems={"center"}
                  mt={"40px"}
                  justifyContent={""}
                  gap={"40px"}
                >
                  <Text color={"gray.300"} fontSize={"40px"}>
                    5
                  </Text>
                  <Text fontSize={"17px"} fontWeight={""} textAlign={"start"}>
                    Projects can't involve prohibited items.
                  </Text>
                </Flex>
                <Button
                  mt={"15px"}
                  onClick={() => {
                    setTerms(false);
                  }}
                  colorScheme="orange"
                  width={"200px"}
                  fontSize={"14px"}
                  fontWeight={500}

                >
                  Got it
                </Button>
                <Text mt={"20px"} fontSize={""} textDecoration={"underline"}>
                  Read more about our rules.
                </Text>
              </Container>
            </Box>
            :
            <Button
              float={"left"}
              onClick={() => {
                setTerms(true);
              }}
              width={"200px"}
              fontSize={"14px"}
              fontWeight={500}
              mt={"20px"}
            >
              View Terms
            </Button>

        }

        <Box>
          <Text mt={"80px"} textAlign={"start"} fontSize={"25px"}>
            Project overview
          </Text>

          <Box mt={"25px"} bg={"gray.50"} border={"1px solid rgba(0,0,0,0.2)"}>

            <Flex
              px={"20px"}
              onClick={() => onTabClick("Basics")}
              cursor={"pointer"}
              py={"30px"}
              alignItems={"center"}
              gap={"20px"}
              borderBottom={"1px solid gray"}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                w={"40px"}
                height={"40px"}
                borderRadius={"50%"}
                border={"5px solid gray"}
                p={"5px"}
              >
                <FaCheck />
              </Flex>
              <Box textAlign={"start"}>
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  Basics
                </Text>
                <Text fontSize={""} color={"gray.500"}>
                  Name your project, upload an image or video, and establish
                  your campaign details.
                </Text>
              </Box>
            </Flex>

            <Flex
              px={"20px"}
              py={"30px"}
              onClick={() => onTabClick("Rewards")}
              cursor={"pointer"}
              alignItems={"center"}
              gap={"20px"}
              borderBottom={"1px solid gray"}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                w={"40px"}
                height={"40px"}
                borderRadius={"50%"}
                border={"5px solid gray"}
                p={"5px"}
              >
                <FaCheck />
              </Flex>
              <Box textAlign={"start"}>
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  Rewards
                </Text>
                <Text fontSize={""} color={"gray.500"}>
                  Set your rewards and shipping costs.
                </Text>
              </Box>
            </Flex>

            <Flex
              px={"20px"}
              py={"30px"}
              onClick={() => onTabClick("Story")}
              cursor={"pointer"}
              alignItems={"center"}
              gap={"20px"}
              borderBottom={"1px solid gray"}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                w={"40px"}
                height={"40px"}
                borderRadius={"50%"}
                border={"5px solid gray"}
                p={"5px"}
              >
                <FaCheck />
              </Flex>
              <Box textAlign={"start"}>
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  Story
                </Text>
                <Text fontSize={""} color={"gray.500"}>
                  Add a detailed project description and convey your risks and challenges.
                </Text>
              </Box>
            </Flex>

            <Flex
              px={"20px"}
              py={"30px"}
              alignItems={"center"}
              onClick={() => onTabClick("People")}
              cursor={"pointer"}
              gap={"20px"}
              borderBottom={"1px solid gray"}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                w={"40px"}
                height={"40px"}
                borderRadius={"50%"}
                border={"5px solid gray"}
                p={"5px"}
              >
                <FaCheck />
              </Flex>
              <Box textAlign={"start"}>
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  People
                </Text>
                <Text fontSize={""} color={"gray.500"}>
                  Edit your GorillaFunder profile and add collaborators.
                </Text>

              </Box>
            </Flex>

            <Flex
              px={"20px"}
              py={"30px"}
              alignItems={"center"}
              gap={"20px"}
              onClick={() => onTabClick("Payment")}
              cursor={"pointer"}
              borderBottom={"1px solid gray"}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                w={"40px"}
                height={"40px"}
                onClick={() => onTabClick("Payment")}
                cursor={"pointer"}
                borderRadius={"50%"}
                border={"5px solid gray"}
                p={"5px"}
              >
                <FaCheck />
              </Flex>
              <Box textAlign={"start"}>
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  Payment
                </Text>
                <Text fontSize={""} color={"gray.500"}>
                  Verify details and link a bank account.
                </Text>
                <Text fontSize={"13px"} mt={"5px"} color={"blue"}>
                  This may take up to 3 business days to complete.
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>

        <Flex alignItems={"center"} gap={"20px"} mt={"20px"}>
          <Box bg={"gray.200"} w={"12px"} h={"12px"} borderRadius={"50%"}></Box>
          <Text fontSize={""}>Submit your project for review</Text>
        </Flex>

        <Flex
          mt={"25px"}
          px={"20px"}
          py={"30px"}
          alignItems={"center"}
          gap={"20px"}
          border={"1px solid rgba(0,0,0,0.2)"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            w={"40px"}
            height={"40px"}
            borderRadius={"50%"}
            border={"5px solid gray"}
            p={"5px"}
          >
            <FaCheck />
          </Flex>
          <Box textAlign={"start"}>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              Project review
            </Text>
            <Text fontSize={""} color={"gray.500"}>
              We’ll check to make sure it follows our rules and guidelines.
            </Text>
            <Text fontSize={"13px"} mt={"5px"} color={"blue"}>
              Please allow 1-3 business days for a response.
            </Text>
          </Box>
        </Flex>

        <Flex alignItems={"center"} gap={"20px"} mt={"20px"}>
          <Box bg={"gray.200"} w={"12px"} h={"12px"} borderRadius={"50%"}></Box>
          <Text fontSize={""}>Prepare for your lunch</Text>
        </Flex>

        <Flex
          mt={"25px"}
          px={"20px"}
          py={"30px"}
          alignItems={"center"}
          onClick={() => onTabClick("Promotion")}
          cursor={"pointer"}
          gap={"20px"}
          border={"1px solid rgba(0,0,0,0.2)"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            w={"40px"}
            height={"40px"}
            borderRadius={"50%"}
            border={"5px solid gray"}
            p={"5px"}
          >
            <FaCheck />
          </Flex>
          <Box textAlign={"start"}>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              Promotion
            </Text>
            <Text fontSize={""} color={"gray.500"}>
              Generate a project URL and activate your pre-launch page.
            </Text>

          </Box>
        </Flex>

        <Flex alignItems={"center"} gap={"20px"} mt={"20px"}>
          <Box bg={"gray.200"} w={"12px"} h={"12px"} borderRadius={"50%"}></Box>
          <Text fontSize={""}>Launch</Text>
        </Flex>

        <Divider my={"20px"} />
        <Text textAlign={"start"} fontSize={"25px"} mb={"15px"}>
          Support
        </Text>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap={"20px"}
          mb={"50px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            w={"100%"}
            pb={"60px"}
            px={"30px"}
            pt={"30px"}
            bg={""}
            border={"1px solid rgba(0,0,0,0.2)"}
          >
            <Flex alignItems={"center"} gap={"10px"}>
              <Text textAlign={"start"} fontSize={"20px"}>
                Creator Resources
              </Text>
              <FaLongArrowAltRight />
            </Flex>
            <Text textAlign={"start"} mt={"10px"} fontSize={""}>
              Learn about everything from shipping to communicatng with backers.
            </Text>
          </Box>

          <Box
            w={"100%"}
            pb={"60px"}
            px={"30px"}
            pt={"30px"}
            bg={""}
            border={"1px solid rgba(0,0,0,0.2)"}
          >
            <Flex alignItems={"center"} gap={"10px"}>
              <Text textAlign={"start"} fontSize={"20px"}>
                Creator Questions
              </Text>
              <FaLongArrowAltRight />
            </Flex>
            <Text textAlign={"start"} mt={"10px"} fontSize={""}>
              Learn about everything from shipping to communicatng with backers.
            </Text>
          </Box>
        </Flex>

        {
          !filmDetails?.published
          &&
          <>
          <Divider my={"30px"} />

        <Flex _hover={{ bg: "red.100" }}
          onClick={onTrashOpen}
          mb={5} cursor={"pointer"} p={"10px"} w={"fit-content"} gap={"10px"} alignItems={"center"}>
          <RiDeleteBinLine color="red" size={"20px"} />
          <Text color="red">Move to Trash</Text>
        </Flex>
        </>}



        {/* Modal for reply to the faq */}
        <Modal isOpen={isTrashOpen} onClose={onTrashClose} size={"xl"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Deleting - {filmDetails?.basic?.title} - Project

            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>

              <Text mb={5}>
                Are you sure you want to delete this project? You can't undo this action.
              </Text>


            </ModalBody>

            <ModalFooter>
              <Button colorScheme="orange" size={"sm"} mr={3} onClick={submitTrashAnswer}>
                Yes Delete
              </Button>
              <Button size={"sm"} onClick={onTrashClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>



      </FluidContainer>
      <Footer />
    </>
  );
};

export default Project;
