import {
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Input,
  Box,
  Button,
  Text,
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"; // Import NavLink from React Router
import { AppContext } from "../Context/AuthContextProvider";
import SubNavbar from "./SubNavbar";
import useGetUser from "../libs/useGetUser";
import { IoSearch } from "react-icons/io5";
import { AuthModalComponent } from "./AuthModal";
import { LuPhoneIncoming } from "react-icons/lu";
import { TiThMenu } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";

const Navbar = ({ logoLevel, hideSubNav }) => {
  const context = useContext(AppContext);
  const { logout } = context;
  const { user } = useGetUser();
  const [exploreHover, setExploreHover] = useState(false)
  const [subNav, setSubNav] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState("");
  const [navbarTheme, setNavbarTheme] = useState(false)

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation()
  const navigate = useNavigate();
  const logoutClick = () => {
    logout();
    // navigate("/login");
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onMouseOverFun = () => {
    // get class mysubnavbar and set its height to 430px

    // const subnavbar = document.querySelector(".subnavbar");
    // subnavbar.style.height = "430px";
    // subnavbar.style.transition = "height 0.5s";
    // subnavbar.style.color= "black";
    setNavbarTheme(true)
    setExploreHover(true)
    console.log(exploreHover)

  }
  const handleLeave = () => {
    setExploreHover(false)
    setNavbarTheme(false)
  }


  return (
    <Flex
      as="header"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      // borderWidth="1px"
      w="100%"
      className="navbar"
      bg={location.pathname === "/" ? "transparent" : "white"}
      _hover={{ bg: navbarTheme && "white", color: navbarTheme && "black" }}
      color={location.pathname === "/" ? "white" : "black"}
    >
      <Flex
        w="100%"
        // p={["2vh 3vw", "2vh 3vw"]}
        p={{ base: "20px 1px", lg: "20px 60px" }}
        justifyContent="space-between"
        alignItems="center"
        // borderWidth="1px"
        flexDirection={["column", "row"]}
      >
        <Flex alignItems={"center"} gap={{ base: "50px", lg: "30px" }}>
          <Link to="/">
            <Image
              src={
                logoLevel === "2"
                  ? (location.pathname === "/" ? "/images/blacklogo.png" : "/images/whitelogo.png")
                  : (location.pathname === "/"
                    ? (navbarTheme ? "/images/whitelogo.png" : "/images/blacklogo.png")
                    : "/images/whitelogo.png")
              }
              alt="gorilla-funder"
              ml={{
                base: "2",
                md: "0",
                lg: "0",
              }}
              h={"35px"}
            w={"250px"}
            />
          </Link>
          <Link to="/view-campaigns" onMouseOver={onMouseOverFun} >
            <Text color={""} display={{ base: "none", lg: "block" }} className="explore " cursor={"pointer"}>
              Explore
            </Text>
          </Link>
          <Box display={{ base: "flex", lg: "none" }}  >
            {subNav ? (
              <>
                <Box onClick={() => setSubNav(false)}>
                  <RxCross2 />
                </Box>
              </>
            )
              :
              (
                <>
                  <Box onClick={() => setSubNav(true)}>
                    <TiThMenu />
                  </Box>
                </>
              )
            }

          </Box>
        </Flex>



        <Box display={{ base: "none", lg: "block" }} bg={""} w={{ base: "100%", lg: "400px" }}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <IoSearch />
            </InputLeftElement>
            <Input
              // bg={"rgba(f,f,f,0.1)"}
              bg="rgba(255, 255, 255, 0.25)"
              // boxShadow="0 8px 32px 0 rgba(31, 38, 135, 0.37)"
              backdropFilter="blur(0.5px)"
              webkitBackdropFilter="blur(0.5px)"
              borderRadius="10px"
              sx={
                navbarTheme ? {
                  '::placeholder': {
                    color: 'gray',
                  },
                } : {
                  '::placeholder': {
                    color: 'white',
                  },
                }
              }
              border="1px solid rgb(212 212 212)"
              type="tel"
              placeholder={
                selectedCategory ? "Search in " + selectedCategory : "Search for campaigns - press enter"
              }
              outline={"none"}
              // border={"none"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate(`/view-campaigns?search=${e.target.value}`)
                  handleLeave()
                }
              }}

              onFocus={onMouseOverFun}
            />
          </InputGroup>
        </Box>

        <Flex
          alignItems={"center"}
          gap={["1vw", "2vw"]}
          sx={{ cursor: "pointer" }}
          onClick={user ? handleMenuOpen : logoutClick}
          display={{ base: "none", lg: "flex" }}
        >
          {/* Use NavLink from React Router */}

          {user ? (
            <Menu>
              <MenuButton>
                <Avatar
                  size="sm"
                  name={user?.name}
                  src={process.env.REACT_APP_API_BASE_URL + user?.avatar}
                  borderRadius="50%"
                />
              </MenuButton>
              <MenuList zIndex={"9999"} color={"black"}>
                <MenuItem
                  onClick={() => navigate(`/view-profile`)}
                >
                  My Profile
                </MenuItem>
                <MenuItem
                  onClick={() => navigate(`/view-profile?tab=Campaigns`)}
                >
                  My Campaign
                </MenuItem>
                <MenuItem
                  onClick={() => navigate(`/view-profile?tab=Contributions`)}
                >
                  My Contributions
                </MenuItem>

                {user.role === "admin" && (
                  <>
                    <MenuItem onClick={() => navigate(`/approval-requests`)}>
                      Approval Requests
                    </MenuItem>
                   
                    <MenuItem onClick={() => navigate(`/all-projects`)}>
                      All Projects
                    </MenuItem>

                    {/* <MenuItem onClick={() => navigate(`/my-backlist`)}>
                      My Backlist
                    </MenuItem> */}

                    <MenuItem onClick={() => navigate(`/withdraw-requests`)}>
                      Withdraw Request
                    </MenuItem>

                    <MenuItem onClick={() => navigate(`/all-creators`)}>
                      All Creators
                    </MenuItem>

                    <MenuItem onClick={() => navigate(`/settings`)}>
                      Settings
                    </MenuItem>
                  </>
                )}

                {user.role === "user" && (
                  <>
                    {/* <MenuItem onClick={() => navigate(`/my-projects`)}>
                      My Projects
                    </MenuItem>

                    <MenuItem onClick={() => navigate(`/my-backlist`)}>
                      My Backlist
                    </MenuItem> */}


                    <MenuItem
                      onClick={() =>
                        navigate(`/settings`)
                      }
                    >
                      Settings
                    </MenuItem>


                    {/* <MenuItem
                      onClick={() =>
                        navigate(`/settings?tab=Payment%20Methods`)
                      }
                    >
                      Payment Methods
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        navigate(`/settings?tab=Shipping%20Address`)
                      }
                    >
                      Shipping Address
                    </MenuItem> */}
                  </>
                )}

                <MenuItem onClick={logoutClick}>Logout</MenuItem>
              </MenuList>
            </Menu>
          ) : (


            <AuthModalComponent />

          )}

          <NavLink to="/create-project" className="active-link">
            <Text
              display={{ base: "none", lg: "block" }}
              fontSize={"15px"}
              fontWeight={"600"}
              textTransform={"uppercase"}
              _hover={{ bg: "orange" }}
              border={"2px solid orange"}
              borderRadius={"8px"}
              bg={location.pathname === "/" ? "transparent" : "orange"}
              py={"10px"}
              px={"10px"}
            >
              {" "}
              Start a Campaign
            </Text>
          </NavLink>
        </Flex>
      </Flex>
      {!hideSubNav && (
        <Box w={"full"} className="" background={"white"} onMouseLeave={handleLeave}>
          <SubNavbar exploreHover={exploreHover} subNav={subNav}  handleLeave={handleLeave} 
          setSelectedCategory={setSelectedCategory}
          />
        </Box>
      )}
    </Flex>
  );
};

export default Navbar;
