import React from 'react'
import Navbar from '../../Components/Navbar'
import { Box, Container, Divider, Image, Text, VStack } from '@chakra-ui/react'
import Footer from '../../Components/Footer'


import MyForm from '../../Components/ContactForm/ContactForm'

const ContactUs = () => {
  return (
    <>

<VStack spacing={"auto"}>
			<Navbar />
				{/* <HomepageComponent /> */}
                <Box
        backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        h={"250px"}
        w={"full"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={{base:"40px",lg:"96px"}} fontWeight={"500"} color={"white"}>
Contact Us

        </Text>
      </Box>
                <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
                {/* <Grid templateColumns={{ base: "1fr", lg: "2fr 10fr" }} gap={10}> */}
           <MyForm/>
                
                </Container>
		</VStack>
			<Footer  />
    </>
  )
}

export default ContactUs