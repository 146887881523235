import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../Context/AuthContextProvider';
import axiosInstance from './axiosService';

const useGetUser = () => {
  const { user, setUser } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('gf-token');

    const fetchUser = async () => {
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get('/user', {
          headers: { Authorization: `${token}` }
        });

        const fetchedUser = response.data?.value;
        console.log('Fetched User:', fetchedUser);

        if (fetchedUser) {
          setUser(fetchedUser);
        } else {
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch the user if not present
    if (!user && token) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [user, setUser]);

  return { user, loading };
};

export default useGetUser;
