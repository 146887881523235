import React from 'react'
import Navbar from '../../Components/Navbar'
import { Box, Container, Divider, Text, VStack } from '@chakra-ui/react'
import Footer from '../../Components/Footer'

import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
  } from '@chakra-ui/react'

const AccessiblityStatement = () => {
  return (
    <>

<VStack spacing={"auto"}>
			<Navbar />
				{/* <HomepageComponent /> */}
                <Box
        backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        h={"250px"}
        w={"full"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={{base:"40px",lg:"96px"}} fontWeight={"500"} color={"white"}>
                 
        Accessibility Statement

        </Text>
      </Box>
                <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
                {/* <Grid templateColumns={{ base: "1fr", lg: "2fr 10fr" }} gap={10}> */}
                <Box textAlign={"start"}>

                <Text fontSize="2xl" fontWeight="bold">
        GorillaFunder, PBC is committed to making its platform available to everyone. We are constantly striving to improve the accessibility of our platform.
      </Text>

      <Text fontSize="xl" fontWeight="semibold" mt={8}>
        Measures to support accessibility
      </Text>

      <Text fontSize="lg" mt={4}>
        GorillaFunder, PBC takes the following measures to support accessibility of GorillaFunder:
      </Text>

      <UnorderedList spacing={2} mt={4}>
        <ListItem>Include accessibility throughout our internal policies.</ListItem>
        <ListItem>Provide continual accessibility training for our staff.</ListItem>
        <ListItem>Assign clear accessibility targets and responsibilities.</ListItem>
      </UnorderedList>

      <Text fontSize="xl" fontWeight="semibold" mt={8}>
        Feedback
      </Text>

      <Text fontSize="lg" mt={4}>
        If you have any questions or concerns about accessibility, please contact us. We welcome your feedback.
      </Text>

      <UnorderedList spacing={2} mt={4}>
        <ListItem>Email: accessibility@gorillafunder.com</ListItem>
        <ListItem>Postal address: 1112 Van Buren Ave, Oxford, MS 38655</ListItem>
      </UnorderedList>

      <Text fontSize="lg" mt={4}>
        We try to respond to feedback within 5 business days.
      </Text>

      <Text fontSize="lg" mt={4}>
        This statement was updated on October 05th, 2024.
      </Text>
                </Box>
                </Container>
		</VStack>
			<Footer  />
    </>
  )
}

export default AccessiblityStatement