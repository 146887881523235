/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaEdit, FaImage, FaTrash, FaTrashAlt } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import axiosInstance from "../../../../../../../libs/axiosService";
import { reward_tiers_initial_state } from "../../../../../../../libs/constants";
import { RiErrorWarningLine } from "react-icons/ri";
import ReactSelect from 'react-select'
import { useParams } from "react-router-dom";
import useUpdateProject from "../../../../../../../libs/useUpdateProjectService";

const NewReward = ({ project, setRewardList, setNewReward, editReward, setEditReward }) => {
  const [newitem, setnewitem] = React.useState(false);
  const [itemAdded, setItemAdded] = React.useState(false);

  const [formData, setFormData] = React.useState(reward_tiers_initial_state);

  useEffect(() => {
    if (editReward) {
      setFormData(editReward);
      if (editReward?.image) {
        setSelectedImage(editReward.image);
      }
    }
  }, [editReward]);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleNestedChange = (e, category, key) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [key]: value,
      },
    }));
  };

  // Deep merge function
  const deepMerge = (target, source) => {
    for (const key of Object.keys(source)) {
      if (source[key] instanceof Object && key in target) {
        Object.assign(source[key], deepMerge(target[key], source[key]));
      }
    }
    return { ...target, ...source };
  };

  const handleRadioChange = (value, category) => {
    const keys = category.split('.');
    let updateBody = {};
    let currentLevel = updateBody;

    keys.forEach((k, index) => {
      if (index === keys.length - 1) {
        currentLevel[k] = value;
      } else {
        currentLevel[k] = {};
        currentLevel = currentLevel[k];
      }
    });

    // Update state with deep merge
    setFormData((prevData) => deepMerge(prevData, updateBody));
  };


  const handleNestedRadioChange = (value, category, key) => {
    console.log(value, category, key);
    setFormData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [key]: value,
      },
    }));
  };

  const handleSaveReward = (type) => {
    console.log("formData", formData);
    if (editReward) {
      // Update the reward in the list
      const updatedList = project.rewards.reward_tiers.map((reward) => {
        if (reward._id === editReward._id) {
          return formData;
        }
        return reward;
      });
      console.log("formData2", formData);
      setRewardList(updatedList);
    } else {
      const updatedProject = { ...project };
      updatedProject.rewards.reward_tiers.push(formData);
      setRewardList(updatedProject.rewards.reward_tiers);
    }
    if (type !== "select-item") {
      setNewReward(false);
    }
  };




  const [selectedImage, setSelectedImage] = React.useState(null);


  const handleImageUpload = async (event, type) => {
    console.log("type", type);
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);


    try {
      const response = await axiosInstance.post('/upload', formData); // Adjust the URL according to your API

      if (type === "rewardImage") {
        setSelectedImage(response.data.urls.image);
        setFormData((prevData) => ({
          ...prevData,
          image: response.data.urls.image,
        }));
      }
      if (type === "newItemImage") {
        setNewItemImage(response.data.urls.image);
      }

    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      console.log('Image uploaded successfully');
    }
  };

  const handleImageDelete = (url, type) => {
    // get the image name from the URL
    const imageName = url.split('/').pop();
    // send a DELETE request to the server
    axiosInstance.delete(`/uploads/${imageName}`)
      .then(response => {
        // clear the selected image
        setSelectedImage(null);
        if (type === "rewardImage") {
          // set formData.image to an empty string
          setFormData((prevData) => ({
            ...prevData,
            image: "",
          }));
        }
        if (type === "newItemImage") {
          setNewItemImage("");
        }
      })
      .catch(error => console.error('Error deleting image:', error));

    setSelectedImage(null);
  };


  // selecting items in rewards
  const [projectItems, setProjectItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [newitemTitle, setNewItemTitle] = React.useState("");
  const [newItemImage, setNewItemImage] = React.useState("");

  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions) {
      const selectedValues = selectedOptions.map(option => option.value);
      setSelectedItems(selectedValues);
      console.log(selectedValues); // This will log the array of selected values
    } else {
      setSelectedItems([]);
      console.log([]); // This will log an empty array if no options are selected
    }
  };


  useEffect(() => {

    if (formData?.items?.length > 0) {
      // console.log("formData.items", formData.items);
      setSelectedItems(formData.items);
    }

  }, [formData]);

  useEffect(() => {

    if (project?.rewards?.reward_items.length > 0) {
      setProjectItems(project.rewards.reward_items);
    }
    // 
  }, [project]);


  const { id } = useParams();
  const { updateProjectField } = useUpdateProject(id);

  useEffect(() => {
    if (projectItems.length > 0) {
      updateProjectField("rewards", { reward_items: projectItems });
    }

  }, [projectItems, updateProjectField]);

  useEffect(() => {

    if (formData && selectedItems.length > 0) {

      setFormData((prevData) => ({
        ...prevData,
        items: selectedItems,
      }));

      setTimeout(() => {
        handleSaveReward("select-item")
      }, 500);
    }

  }, [selectedItems]);

  const onDelete = (index) => {
    const items = formData.items.filter((item, i) => i !== index);
    const updatedFormData = {
      ...formData,
      items: items,
    };

    setFormData(updatedFormData);

    setTimeout(() => {
      handleSaveReward("select-item")
    }, 500);

  }


  return (
    <>
      <Box textAlign={"start"}>
        <Text fontSize={"35px"} fontWeight={""} color={""}>
          {editReward ? "Update " : "Add a"} reward
          {editReward ? <Button size="sm" float={"right"} onClick={
            () => {
              setFormData(reward_tiers_initial_state)
              setEditReward(null)
              setSelectedImage(null)
            }
          }>
            Skip Updating : Create New
          </Button> :
            <Button size="sm" float={"right"} onClick={
              () => {
                setFormData(reward_tiers_initial_state)
                setEditReward(null)
                setNewReward(false)
              }
            }>
              Go Back
            </Button>

          }
        </Text>
        <Text mt={""} fontSize={""} fontWeight={""} color={""}>
          Offer tangible or intangible things that bring backers closer to your
          project.
        </Text>

        <Box mt={"50px"}>
          <Flex alignItems={"center"} gap={"20px"}>
            <Box w={"100%"}>
              <Text fontSize={""} fontWeight={""} color={""}>
                Title
              </Text>
              <Input
                w={"100%"}
                mt={"5px"}
                placeholder="Signed limited-edition"
                size="md"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </Box>
          </Flex>

          <Divider my={"30px"} />

          <Box>
            <Text fontSize={"20px"} fontWeight={"500"} color={""}>
              Pricing
            </Text>
            <Text fontSize={""} fontWeight={""} color={""}>
              Pledge amount
            </Text>
            <InputGroup mt={"10px"}>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                type="number"
                placeholder="500"
                name="pricing"
                value={formData.pricing}
                onChange={handleChange}
              />
            </InputGroup>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Image
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Show your backers what they'll receive for their support. Images
              should be honest, and should avoid banners, badges, and overlaid
              text.
            </Text>
            {(!selectedImage || selectedImage === "") ? (
              <Box
                border={"1px solid rgba(0,0,0,0.1)"}
                p={"40px"}
                width={"50%"}
                bg={"#FBFBFA"}
                position="relative"
              >
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, "rewardImage")}
                  opacity="0"
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  cursor="pointer"
                />
                <Flex justifyContent={"center"} mb={"30px"}>
                  <Box
                    borderRadius={"50%"}
                    bg={"white"}
                    p={"20px"}
                    boxShadow={"1px 1px 10px gray"}
                  >
                    <FaImage size={"30px"} />
                  </Box>
                </Flex>
                <Text
                  fontSize={""}
                  textAlign={"center"}
                  fontWeight={""}
                  color={""}
                >
                  Drop an image here or select a file
                </Text>
                <Text
                  fontSize={"14px"}
                  textAlign={"center"}
                  fontWeight={""}
                  mt={"5px"}
                  color={"#656E72"}
                >
                  It must be a JPG, PNG, GIF, or WEBP, no larger than 50 MB.
                </Text>
              </Box>
            ) : (
              <Box position="relative" width={"50%"}>
                <Image src={process.env.REACT_APP_API_BASE_URL + selectedImage} alt="Selected" borderRadius="10px" />
                <IconButton
                  icon={<FaTrashAlt />}
                  position="absolute"
                  top="10px"
                  right="10px"
                  onClick={() => handleImageDelete(selectedImage, "rewardImage")}
                  aria-label="Delete Image"
                />
              </Box>
            )}
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Description (optional)
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Describe what makes this reward stand out from your other
              offerings. Avoid re-listing items as this will look repetitive to
              backers.
            </Text>
            <Textarea
              mt={"20px"}
              placeholder="Get an early copy"
              resize={"none"}
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Box>

          <Divider my={"30px"} />


          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Items *
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Including items in your rewards and add-ons makes it easy for
              backers to understand and compare <br /> your offerings. An item
              can be anything you plan to offer your backers. Some examples
              include <br /> playing cards, a digital copy of a book, a ticket
              to a play, or even a thank-you in your documentary.
            </Text>


            {
              formData?.items?.map((item, index) =>
              (
                <Flex
                  p={4}
                  mb={4}
                  borderWidth={1}
                  borderRadius="md"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex alignItems="center">
                    <Image
                      src={process.env.REACT_APP_API_BASE_URL + item.image}
                      alt={item.title}
                      boxSize="100px"
                      objectFit="cover"
                      mr={4}
                    />
                    <Box sx={{ textAlign: "left", marginLeft: "20px" }}>
                      <Text fontWeight="bold" fontSize="lg">
                        {item.title}
                      </Text>

                    </Box>
                  </Flex>
                  <Flex>

                    <IconButton
                      icon={<FaTrash />}
                      onClick={() => onDelete(index)}
                    />
                  </Flex>
                </Flex>
              ))
            }

            {newitem && (
              <>
                <Box
                  my={"20px"}
                  bg={""}
                  p={"20px"}
                  border={"1px solid rgba(0,0,0,0.1)"}
                >
                  <Box>
                    <Text fontSize={""}>Add an existing item</Text>

                    <ReactSelect
                      mt="10px"
                      placeholder="Choose one"
                      options={projectItems.map(item => ({ value: item, label: item?.title }))}
                      onChange={handleSelectChange}
                      value={selectedItems.map(item => ({ value: item, label: item?.title }))}
                      isOptionDisabled={(option) => {
                        return selectedItems.includes(option.value);
                      }
                      }
                      isMulti
                      size="md"
                    />
                    <Text my={"10px"} fontSize={""}>
                      Or
                    </Text>

                    <Text mt={"10px"} fontSize={""}>Create a new item</Text>
                    <Input
                      mt={"10px"}
                      type="text"
                      onChange={(e) => setNewItemTitle(e.target.value)}
                      value={newitemTitle}
                      placeholder="30"
                      size="md"
                    />
                  </Box>

                  <Text mt={"10px"} fontSize={""}>
                    Item image (optional)
                  </Text>
                  <Box>
                    <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
                      Image
                    </Text>
                    <Text fontSize={""} fontWeight={""} color={"#656969"}>
                      Show your backers what they'll receive for their support. Images
                      should be honest, and should avoid banners, badges, and overlaid
                      text.
                    </Text>
                    {(!newItemImage || newItemImage === "") ? (
                      <Box
                        border={"1px solid rgba(0,0,0,0.1)"}
                        p={"40px"}
                        width={"50%"}
                        bg={"#FBFBFA"}
                        position="relative"
                      >
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e, "newItemImage")}
                          opacity="0"
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          cursor="pointer"
                        />
                        <Flex justifyContent={"center"} mb={"30px"}>
                          <Box
                            borderRadius={"50%"}
                            bg={"white"}
                            p={"20px"}
                            boxShadow={"1px 1px 10px gray"}
                          >
                            <FaImage size={"30px"} />
                          </Box>
                        </Flex>
                        <Text
                          fontSize={""}
                          textAlign={"center"}
                          fontWeight={""}
                          color={""}
                        >
                          Drop an image here or select a file
                        </Text>
                        <Text
                          fontSize={"14px"}
                          textAlign={"center"}
                          fontWeight={""}
                          mt={"5px"}
                          color={"#656E72"}
                        >
                          It must be a JPG, PNG, GIF, or WEBP, no larger than 50 MB.
                        </Text>
                      </Box>
                    ) : (
                      <Box position="relative" width={"50%"}>
                        <Image src={process.env.REACT_APP_API_BASE_URL + newItemImage} alt="Selected" borderRadius="10px" />
                        <IconButton
                          icon={<FaTrashAlt />}
                          position="absolute"
                          top="10px"
                          right="10px"
                          onClick={() => handleImageDelete(newItemImage, "newItemImage")}
                          aria-label="Delete Image"
                        />
                      </Box>
                    )}
                  </Box>



                  <Button
                    mt={"20px"}
                    w={"100%"}
                    colorScheme="orange"
                    fontSize={"14px"}
                    fontWeight={500}
                    onClick={() => {
                      setProjectItems([
                        ...projectItems,
                        {
                          title: newitemTitle,
                          image: newItemImage,
                        },
                      ]);
                      setNewItemTitle("");
                      setNewItemImage("");
                      setItemAdded(true)
                      // setnewitem(false);
                    }
                    }
                  >
                    Save
                  </Button>
                  {itemAdded &&
                    <p className="item-added">
                      Item has been added, please select the item from the dropdown.
                    </p>}
                  <Button
                    onClick={() => {
                      setnewitem(false);
                      setItemAdded(false);
                    }}
                    mt={"10px"}
                    w={"100%"}
                    colorScheme="gray"
                    fontSize={"14px"}
                    fontWeight={500}
                    
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            )}



            {!newitem && <Button
              onClick={() => setnewitem(true)}
              mt={"10px"}
              w={"100%"}
              colorScheme="orange"
              fontSize={"14px"}
              fontWeight={500}

            >
              New Item
            </Button>}

          </Box>
          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Contents
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Will your backers receive any physical goods?
            </Text>

            <RadioGroup
              onChange={(value) => handleRadioChange(value, "content.content")}
              value={formData.content.content === "yes" ? "yes" : "no"}
            >
              <Stack>
                <Box border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="yes">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      Yes
                    </Text>
                  </Radio>

                  {formData.content.content === "yes" && (
                    <>
                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Which of the following best applies?
                      </Text>

                      <RadioGroup
                        onChange={(value) =>
                          handleNestedRadioChange(value, "content", "content_type")
                        }
                        value={formData.content.content_type}
                      >
                        <Stack>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="physical-goods">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Only physical goods
                              </Text>
                            </Radio>
                          </Box>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="physical-digital-goods">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Physical and digital goods/services
                              </Text>
                            </Radio>
                          </Box>
                        </Stack>
                      </RadioGroup>
                    </>
                  )}
                </Box>
                <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="no">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      No, only digital goods and/or services
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>

            <Flex mt={"20px"} alignItems={"center"} gap={"10px"}>
              <CiLocationOn size={"20px"} color="orange" fontWeight={"bold"} />
              <Text fontSize={"12px"} fontWeight={""} color={"#656969"}>
                Make sure that your description of the physical goods meets
                GorillaFunder's{" "}
                <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                  requirements.
                </span>
              </Text>
            </Flex>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Shipping
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Do you need to collect a shipping address?
            </Text>

            <RadioGroup
              onChange={(value) => handleRadioChange(value, "shipping.shipping")}
              value={formData.shipping.shipping === "yes" ? "yes" : "no"}
            >
              <Stack>
                <Box border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="yes">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      Yes
                    </Text>
                  </Radio>

                  {formData.shipping.shipping === "yes" && (
                    <>
                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Which of the following best applies?
                      </Text>

                      <RadioGroup
                        onChange={(value) =>
                          handleNestedRadioChange(value, "shipping", "shipping_type")
                        }
                        value={formData.shipping.shipping_type}
                      >
                        <Stack>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="only-physical">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Only physical goods
                              </Text>
                            </Radio>
                          </Box>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="physical-and-digital">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Physical and digital goods/services
                              </Text>
                            </Radio>
                          </Box>
                        </Stack>
                      </RadioGroup>

                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Where will you ship to?
                      </Text>

                      <Select
                        placeholder="Select option"
                        name="shipping.destination"
                        value={formData.shipping.destination}
                        onChange={(e) => handleNestedChange(e, "shipping", "destination")}
                      >
                        <option value="domestic">Domestic (only in your country)</option>
                        <option value="international">International (anywhere in the world)</option>
                      </Select>

                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Shipping cost
                      </Text>
                      <Input
                        mt={"10px"}
                        type="number"
                        placeholder="Enter shipping cost"
                        name="shipping.shipping_cost"
                        value={formData.shipping.shipping_cost}
                        onChange={(e) => handleNestedChange(e, "shipping", "shipping_cost")}
                      />
                    </>
                  )}
                </Box>
                <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="no">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      No, only digital goods and/or services
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Estimated delivery
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              When do you expect to deliver this reward to your backers?
            </Text>

            <Flex gap={"20px"} mt={"20px"}>
              <Select
                placeholder="Month"
                name="estimated_delivery_month"
                value={formData.estimated_delivery_month}
                onChange={handleChange}
              >
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month) => (
                  <option value={month} key={month}>
                    {month}
                  </option>
                ))}
              </Select>
              <Input
                type="number"
                placeholder="Year"
                name="estimated_delivery_year"
                value={formData.estimated_delivery_year}
                onChange={handleChange}
              />
            </Flex>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Project quantity
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Do you need to limit the quantity available of this reward?
            </Text>

            <RadioGroup
              onChange={(value) => handleRadioChange(value, "project_quantity.project_quantity")}
              value={formData.project_quantity.project_quantity === "yes" ? "yes" : "no"}
            >
              <Stack>
                <Box border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="yes">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      Yes
                    </Text>
                  </Radio>

                  {formData.project_quantity.project_quantity === "yes" && (
                    <>
                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Which of the following best applies?
                      </Text>

                      <RadioGroup
                        onChange={(value) =>
                          handleNestedRadioChange(value, "project_quantity", "quantity_type")
                        }
                        value={formData.project_quantity.quantity_type}
                      >
                        <Stack>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="available-quantity">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Limit the quantity available
                              </Text>
                            </Radio>
                          </Box>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="available-per-backer">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Limit the quantity available per backer
                              </Text>
                            </Radio>
                          </Box>
                        </Stack>
                      </RadioGroup>

                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Quantity
                      </Text>
                      <Input
                        mt={"10px"}
                        type="number"
                        placeholder="Enter quantity"
                        name="project_quantity.quantity"
                        value={formData.project_quantity.quantity}
                        onChange={(e) => handleNestedChange(e, "project_quantity", "quantity")}
                      />
                    </>
                  )}
                </Box>
                <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="no">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      No
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Time limit
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Do you need to limit the availability of this reward?
            </Text>

            <RadioGroup
              onChange={(value) => handleRadioChange(value, "time_limit.time_limit")}
              value={formData.time_limit.time_limit === "yes" ? "yes" : "no"}
            >
              <Stack>
                <Box border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="yes">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      Yes
                    </Text>
                  </Radio>

                  {formData.time_limit.time_limit === "yes" && (
                    <>
                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Which of the following best applies?
                      </Text>

                      <RadioGroup
                        onChange={(value) =>
                          handleNestedRadioChange(value, "time_limit", "limit_type")
                        }
                        value={formData.time_limit.limit_type}
                      >
                        <Stack>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="end-date">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Set a specific end date
                              </Text>
                            </Radio>
                            {formData.time_limit.limit_type === "end-date" && (
                              <Box mt={"10px"}>
                                <Input
                                  type="date"
                                  placeholder="End date"
                                  name="time_limit.specific_end_date"
                                  value={formData.time_limit.specific_end_date}
                                  onChange={(e) => handleNestedChange(e, "time_limit", "specific_end_date")}
                                />
                              </Box>
                            )}
                          </Box>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="days">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Set the number of days this reward is available
                              </Text>
                            </Radio>
                            {formData.time_limit.limit_type === "days" && (
                              <Box mt={"10px"}>
                                <Input
                                  type="number"
                                  placeholder="Number of days"
                                  name="time_limit.days_available"
                                  value={formData.time_limit.days_available}
                                  onChange={(e) => handleNestedChange(e, "time_limit", "days_available")}
                                />
                              </Box>
                            )}
                          </Box>
                        </Stack>
                      </RadioGroup>
                    </>
                  )}
                </Box>
                <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="no">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      No
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Button colorScheme="orange" size="md" onClick={() => handleSaveReward("reward")}>
              {editReward ? "Update " : "Save"} Reward
            </Button>
            <Button ml={5} colorScheme="gray" size="md" onClick={() => setNewReward(false)}>
              Cancel
            </Button>
          </Box>

        </Box>
      </Box >

    </>
  );
};

export default NewReward;
