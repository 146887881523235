import {
  Flex,
  Heading,
  Select,
  Text,

  useBreakpointValue,
  Box,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Input,
  Button,
} from "@chakra-ui/react";
import React from "react";
import SubNavbar from "./SubNavbar";
import { FaCode, FaEnvelope, FaFacebook, FaTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const ABOUT = [
  // "Our Rule", "Help center", "Newsletter", "Blog"
  // {
  //   link: "/",
  //   title: "Our Rule",
  // },
  {
    link: "/contact-us",
    title: "Help center",
  },
  {
    link: "/",
    title: "Newsletter",
  },
  {
    link: "/about-us",
    title: "About",
  },
  {
    link: "/lower-fees - payment-structure",
    title: "Payment Structure",
  }
];


const subfooter = [
  "Trust & Safety",
  "Terms of Use",
  "Privacy Policy",
  "Cookie Policy",
  "Accessibility Statement",
  // "CA Notice of Consent",
];
const linkMapping = {
  "Trust & Safety": "/trust-and-safety",
  "Terms of Use": "/terms-of-use",
  "Privacy Policy": "/privacy-policy",
  "Cookie Policy": "/cookie-policy",
  "Accessibility Statement": "/accessibility-statements",
};

const Footer = ({ logoLevel, hideSubNav }) => {
  const flexDirFooter = useBreakpointValue({ base: "column", md: "row" });
  const paddingFooter = useBreakpointValue({
    base: "5vh 12vw",
    md: "2vh 12vw",
  });
  const paddingLinks = useBreakpointValue({ base: "1vh 0", md: "1vh 0" });
  // const imageWidth = useBreakpointValue({ base: "10vw", md: "4vw" });
  const textFontSize = useBreakpointValue({ base: "sm", md: "sm" });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const showModal = () => {
    onOpen();
  }

  return (
    <>
      {!hideSubNav && <SubNavbar />}

      <Box bg={"#000"} py={"40px"} px={{ base: "20px", lg: "40px" }} color={"white"}>
        <Box h={"2px"} bg={"#C05621"} mb={"120px"}></Box>
        <Grid templateColumns={{ base: "1fr", lg: "10fr 2fr" }}>
          <GridItem bg={""} colSpan={{ base: "1fr" }}>
            <Box textAlign={"start"}>



              <Text fontSize={{ base: "16px", lg: "18px" }} textAlign={""} lineHeight={"24px"} fontWeight={"400"}>
                Supporting entrepreneurs, creators
                <br /> and creative communities since 2024
              </Text>
              <Button colorScheme="orange" mt={"20px"}
                onClick={() => window.open("https://gorillafilm.com/", "_blank")}
                w={{ base: "100%", lg: "150px" }} py={"20px"}>
                Gorilla Film
              </Button>
              {/* <Text mt={{ base: "30px", lg: "70px" }} fontSize={"18px"} fontWeight={"400"}>
                Follow us!{" "}
              </Text>
              <Box display={"flex"} mt={{ base: '10px', lg: "20px" }} gap={6} alignItems="center">
                <FaFacebook size={"20px"} color="#39579a" />

                <FaTwitter size={"20px"} color="#00abf0" />

                <FaEnvelope size={"20px"} color="#656969" />

                <FaCode size={"20px"} color="#656969" />
              </Box> */}
              {/* <Flex mt={{ base: "40px", lg: "20px" }} gap={"20px"}>
                <Select placeholder="English" w="auto" fontSize={textFontSize}>
                  <option style={{ color: "black" }} value="Spanish">Spanish</option>
                  <option style={{ color: "black" }} value="Chinese">Chinese</option>
                  <option style={{ color: "black" }} value="Deutsch">Deutsch</option>
                </Select>
                <Select
                  placeholder="$ US Dollar (USD)"
                  w="auto"
                  fontSize={textFontSize}
                >
                  <option style={{ color: "black" }} value="EUR">€ Euro (EUR)</option>
                  <option style={{ color: "black" }} value="AUD">$ Australian Dollar (AUD)</option>
                  <option style={{ color: "black" }} value="GBP">£ Pound Sterling (GBP)</option>
                  <option style={{ color: "black" }} value="JPY">¥ Japanese Yen (JPY)</option>
                </Select>
              </Flex> */}
            </Box>
          </GridItem>
          <GridItem mt={{ base: "40px", lg: "0px" }} colSpan={{ base: "1fr" }} textAlign={"start"}>
            <Text fontSize={"18px"} color={"#DD6B20"} fontWeight={"500"}>
              Important Links
            </Text>
            {ABOUT?.map((e, i) => (

              e.title === "Newsletter" ?
                <Box sx={{ cursor: "pointer" }} onClick={showModal}
                  _hover={{
                    textDecoration: "underline"
                  }}
                >
                  <Text fontSize={"18px"} fontWeight={"400"} mt={"8px"}>
                    {e.title}
                  </Text>
                </Box> :
                <Link to={e.link} key={i} mb={paddingLinks}>
                  <Text fontSize={"18px"} fontWeight={"400"} mt={"8px"}>
                    {e.title}
                  </Text>
                </Link>


            ))}
          </GridItem>

        </Grid>

        <Flex justifyContent={"center"} my={"60px"}>
          <Link to="/">
            <Image
              src={logoLevel === "2" ? "/images/blacklogo.png" : "/images/blacklogo.png"}
              alt="gorilla-funder"
              h={["5vh", "14vh"]}
            // w={"100px"}
            />
          </Link>
        </Flex>

        <Box h={"2px"} bg={"#C05621"} my={"40px"}></Box>

        <Flex
          mt={"40px"}
          w="100%"
          justifyContent="space-between"
          flexDir={{ base: "column", lg: "row" }}
          alignItems="center"
          fontSize="sm"
        >
          {/* <Image
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/GorillaFunder-logo-k-color.svg/1796px-GorillaFunder-logo-k-color.svg.png"
            w={imageWidth}
            h="auto"
          /> */}
          <Text mb={{ base: "20px", lg: "0px" }} fontSize={textFontSize}>
            GorillaFunder © {new Date().getFullYear()}{" "}
          </Text>

          <Flex
            fontWeight={"400"}
            gap={"20px"}
            fontSize="14px"
            flexDir={{ base: "", lg: "row" }}
            flexWrap={"wrap"}
            justifyContent={{ base: "center" }}
          >
            {subfooter?.map((ele) => (
              <Link to={`${linkMapping[ele]}`} key={ele} mb={paddingLinks}>
                {ele}
              </Link>
            ))}
          </Flex>
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent maxW="700px" maxH={"300px"} overflowY={"auto"}>
          <ModalCloseButton />

          <ModalBody overflow={"hidden"}>

            <Text fontSize={"24px"} fontWeight={"500"}>
              Get the latest newsletter in your inbox
            </Text>
            <Text fontSize={"16px"} mt={{ base: "10px", lg: "10px" }} fontWeight={"500"}>
              Exclusive weekly updates with new products, drops, and special offers.
            </Text>
            <Flex
              alignItems={"center"}
              gap={"10px"}
              justifyContent={"center"}
              mt={"20px"}
              flexDir={{ base: "column", lg: "row" }}
            >
              <Box w={{ base: "100%", lg: "550px" }}>
                <Input
                  _placeholder={{ color: "white" }}
                  color={"white"}
                  bg={"#262626"}
                  fontSize={"18px"}
                  outline={"none"}
                  _peerActive={{ outline: "none" }}
                  border={"none"}
                  py={"30px"}
                  px={"25px"}
                  placeholder="Your email address"
                />
              </Box>
              <Button colorScheme="orange" w={{ base: "100%", lg: "150px" }} py={"30px"}
                onClick={onClose}
              >
                SUBSCRIBE
              </Button>
            </Flex>
            <Text fontSize={"14px"} mt={"15px"} fontWeight={"500"}>
              By clicking “SUBSCRIBE” I have read and agree to GorillaFunder’s Terms of
              Use and Privacy Policy .
            </Text>

          </ModalBody>
        </ModalContent>
      </Modal>

    </>
  );
};

export default Footer;
