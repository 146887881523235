import React, { useState } from "react";
import {
    Box,
    Text,
    Button,
    useToast,
    Flex,
    Image,
    Avatar,
    InputGroup,
    InputLeftAddon,
    Input,
} from "@chakra-ui/react";
import { RemindMeModal } from "../../../Components/RemindMeModal/RemindMeModal";
import { useNavigate, useParams } from "react-router-dom";

const PerksComponent = ({ filmDetails }) => {

    const [bonus, setBonus] = useState(0);
    const navigate = useNavigate();

    const { id } = useParams();
    const toast = useToast();

    return (

        <>
            <Text
                mt={{ base: "20px", lg: "0px" }}
                ml={{ base: "", lg: "20px" }}
                display={{ base: "none", lg: "block" }}
                mb={"20px"}
                fontSize={"18px"}
                fontWeight={"600"}
                textAlign={"start"}
            >
                Choose your Perk
            </Text>

            <Flex
                display={{ base: "none", lg: "flex" }}
                justifyContent={"center"}

            >
                <Box
                    p={"20px"}
                    pt={"50px"}
                    bg={""}
                    color={""}
                    className="perks-box"
                    h={"90vh"}
                    overflow={"hidden"}
                    overflowY={"auto"}
                >
                    <Box
                        w={"300px"}
                        p={""}
                        position={"relative"}
                        textAlign={"start"}
                        border={"1px solid gray"}
                    >
                        <Box
                            position={"absolute"}
                            top={"-50px"}
                            left={"-10px"}
                            width={"72px"}
                            borderRadius={"50%"}
                        >
                            <Avatar
                                size="lg"
                                name={filmDetails?.people?.user?.name}
                                src={
                                    process.env.REACT_APP_API_BASE_URL +
                                    filmDetails?.people?.user?.avatar
                                }
                            />
                        </Box>
                        <Box p={"20px"}>
                            <Text fontSize={""}>{filmDetails?.people?.user?.name}</Text>
                            <Text mt={"10px"} fontSize={""}>
                                2 created - 1 backed
                            </Text>

                            <Text mt={"30px"} fontSize={""}>
                                {filmDetails?.people?.user?.biography}
                            </Text>
                            <Flex alignItems={"center"} gap={"5px"}>
                                <RemindMeModal
                                    seeMore={"seeMore"}
                                    userDetails={filmDetails?.people?.user}
                                />
                            </Flex>
                        </Box>
                    </Box>

                    <Text mt={"20px"} textAlign={"start"} fontSize={"25px"}>
                        Support
                    </Text>

                    <Box
                        position={"relative"}
                        textAlign={"start"}
                        border={"1px solid gray"}
                        w={"300px"}
                    >
                        <Image
                            src="/images/movies/socialmediashoutout.png"
                            width={""}
                        />
                        <Box p={"20px"}>
                            <Text mt={""} textAlign={"start"} fontSize={""}>
                                Make a pledge without a reward
                            </Text>
                            <Text mt={"20px"} textAlign={"start"} fontSize={""}>
                                Pledge amount
                            </Text>
                            <InputGroup>
                                <InputLeftAddon>US$</InputLeftAddon>
                                <Input
                                    type="tel"
                                    placeholder="$"
                                    onChange={(e) => {
                                        setBonus(e.target.value);
                                    }}
                                />
                            </InputGroup>

                            <Box
                                backgroundImage={
                                    " -webkit-linear-gradient(top, #DBE7FF, #FFF2EC)"
                                }
                                p={"20px"}
                                mt={"15px"}
                            >
                                <Text mt={""}>Back it because you believe in it.</Text>
                                <Text mt={"20px"}>
                                    Support the project for no reward, just because it speaks
                                    to you.
                                </Text>
                            </Box>

                            <Button
                                mt={"25px"}
                                borderRadius={"5px"}
                                colorScheme="orange"
                                width="100%"
                                disabled={bonus === 0}
                                onClick={() => {
                                    if (bonus === 0) {
                                        toast({
                                            title: "Error",
                                            description:
                                                "Pledge amount should be greater than 0.",
                                            status: "error",
                                            duration: 3000,
                                            isClosable: true,
                                        });
                                        return;
                                    } else {
                                        navigate(`/checkout/${id}?noReward=${bonus}`);
                                    }
                                }}
                            >
                                PLEDGE NOW
                            </Button>
                        </Box>
                    </Box>

                    <Text mt={"20px"} textAlign={"start"} fontSize={"25px"}>
                        Available Perks
                    </Text>

                    {filmDetails?.rewards?.reward_tiers.map((e, i) => {
                        return (
                            <>
                                <Box
                                    my={"20px"}
                                    position={"relative"}
                                    textAlign={"start"}
                                    border={"1px solid gray"}
                                    w={"300px"}
                                >
                                    <Image
                                        src={
                                            process.env.REACT_APP_API_BASE_URL + e.image ||
                                            "/images/movies/socialmediashoutout.png"
                                        }
                                        width={""}
                                    />
                                    <Box p={"20px"}>
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Text fontWeight={"bold"}>{e?.title}</Text>
                                        </Box>
                                        <Text fontSize={"14px"}>{e?.description}</Text>
                                            <Text
                                                sx={{
                                                    textAlign: "center",
                                                    fontSize: "32px",
                                                    color: "#DD6B20",
                                                }}
                                            >${parseInt(e?.pricing).toLocaleString()}</Text>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                        >
                                            {/* <Box>
                            <Text fontSize={"12px"}>Backers</Text>
                            <Text fontSize={"12px"}>0</Text>
                          </Box> */}
                                            {e?.shipping?.shipping !== "no" && (
                                                <Box>
                                                    <Text fontSize={"12px"}>Ships to</Text>
                                                    <Text fontSize={"12px"}>
                                                        {e?.shipping?.destination}
                                                    </Text>
                                                </Box>
                                            )}
                                        </Box>
                                        <Text mt={"20px"} fontSize={"12px"}>
                                            Estimated delivery
                                        </Text>
                                        <Text fontSize={"12px"}>
                                            {" "}
                                            {e?.estimated_delivery_month +
                                                " " +
                                                e?.estimated_delivery_year}
                                        </Text>

                                        <Text mt={"20px"} fontSize={"12px"}>
                                            {e?.items.length} item included
                                        </Text>
                                        <Text fontSize={"12px"}>
                                            {e?.items?.map((e) => {
                                                return <span>{e?.title}, </span>;
                                            })}

                                            <Box
                                                width={"100%"}
                                                mt={"15px"}
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                gap={"10px"}
                                            >
                                                {/* <Button borderRadius={"5px"} width="100%">
                          Details
                        </Button> */}
                                                <Button
                                                    borderRadius={"5px"}
                                                    colorScheme="orange"
                                                    width="100%"
                                                    onClick={() =>
                                                        navigate(`/checkout/${id}?rewardId=${e?._id}`)
                                                    }
                                                >
                                                    {/* Pledge US${e?.pricing} */}
                                                    GET THIS PERK
                                                </Button>
                                            </Box>
                                        </Text>
                                    </Box>
                                </Box>
                            </>
                        );
                    })}
                </Box>
            </Flex>



        </>
    );
};

export default PerksComponent;
