import React from 'react'
import Navbar from '../../Components/Navbar'
import { Box, Container, Divider, Text, VStack } from '@chakra-ui/react'
import Footer from '../../Components/Footer'

import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
  } from '@chakra-ui/react'

const LowerFeesPaymentStruct = () => {
  return (
    <>

<VStack spacing={"auto"}>
			<Navbar />
				{/* <HomepageComponent /> */}
                <Box
        backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        h={"250px"}
        w={"full"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={{base:"40px",lg:"96px"}} fontWeight={"500"} color={"white"}>
        
        Lower Fees, Better Film

        </Text>
      </Box>
                <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
                {/* <Grid templateColumns={{ base: "1fr", lg: "2fr 10fr" }} gap={10}> */}
                <Box textAlign={"start"}>
                <Text fontSize={"lg"}>
                GorillaFunder offers creators a more cost-effective way to fund their projects compared to the competition. With a 4% fee on successful campaigns and a processing fee of just 2.5% + 25¢ per transaction, GorillaFunder helps more of your funding reach your project. In comparison, Kickstarter takes a 5% fee and charges payment processing fees of 3% to 5%. Indiegogo also has a 5% platform fee, plus 2.9% + 30¢ per transaction for payment processing. If you’re looking to maximize your funding while keeping costs low, GorillaFunder is the smart choice for filmmakers and creators alike.
                </Text>
                </Box>
                </Container>
		</VStack>
			<Footer  />
    </>
  )
}

export default LowerFeesPaymentStruct