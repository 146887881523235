import React from 'react'
import Navbar from '../../Components/Navbar'
import { Box, Container, Divider, Text, VStack } from '@chakra-ui/react'
import Footer from '../../Components/Footer'

import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
  } from '@chakra-ui/react'

const CookiePolicy = () => {
  return (
    <>

<VStack spacing={"auto"}>
			<Navbar />
				{/* <HomepageComponent /> */}
                <Box
        backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        h={"250px"}
        w={"full"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={{base:"40px",lg:"96px"}} fontWeight={"500"} color={"white"}>
        Cookie Policy

        </Text>
      </Box>
                <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
                
                <Box textAlign={"start"}>
                <Text fontSize={"2xl"} fontWeight={"bold"}>
        Cookie Policy
      </Text>
      <Text fontSize={"lg"} fontWeight={"semibold"} mt={4}>
        Effective Date: October 6th, 2024
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Cookies & Other Tracking Technologies
      </Text>
      <Text fontSize={"lg"} mt={4}>
        This Cookie & Other Tracking Technologies Policy (“Cookie Policy”) explains GorillaFunder’s use of cookies and other tracking technologies to help you understand how we use these technologies to improve and protect GorillaFunder and the GorillaFunder Community. GorillaFunder, our trusted vendors, and our Creators use tracking technologies and other automated data collection tools to understand your use of our services that help people bring creative projects to life (our “Services”).
      </Text>
      <Text fontSize={"lg"} mt={4}>
        This Cookie Policy forms part of our Privacy Policy and should also be read along with our Terms of Use. GorillaFunder may amend this Cookie Policy at any time with changes taking effect immediately at posting.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        What Tracking Technologies Does GorillaFunder Use?
      </Text>
      <Text fontSize={"lg"} mt={4}>
        GorillaFunder commonly uses cookies and pixels. Cookies are small text files that may be saved by your internet browser when you visit a website. These text files then store information about your use of GorillaFunder’s Services that can be retrieved from your device. When you visit GorillaFunder, our web server sends a cookie to your device, which allows us to recognize your device (but not the specific person using it). By associating information in the cookies with your account information, for example, when you log in to our Services, we know that the cookie information relates to your user account.
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Cookies vary in how long they are kept on your device. We use both session cookies and persistent cookies on our website.
      </Text>
      <Text fontSize={"lg"} mt={4}>
        “Session cookies” are temporary cookies that last for only a single browsing session. The information they collect is stored in temporary memory and erased shortly after you leave our website or close your browser.
      </Text>
      <Text fontSize={"lg"} mt={4}>
        “Persistent cookies” are stored on your device until you take steps to remove the cookies or until a particular cookie’s expiration date (often several months after the cookie is placed on your device). Persistent cookies can record information from each visit you make to our website as long as they remain on your device.
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Pixels are snippets of code or tiny invisible images embedded in email and websites that help us learn how you interact with our content (for example, if you clicked on a link or an image).
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Our mobile app uses SDKs and APIs, which can collect certain information about your use of our mobile app, such as information that helps us understand how our mobile app is performing and if the software code has any errors as well as information about the content you like on the mobile app. These SDKs and APIs are typically developed by vendors we work with.
      </Text>
      <Text fontSize={"lg"} mt={4}>
        The tracking technologies we use are set by us, our trusted vendors, and some Creators, and we may link the information collected by tracking technologies to other information collected about you.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        What Types of Information Do Tracking Technologies Collect and Store?
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Tracking technologies often store the name of the website or mobile app you visit and a unique ID associated with your browser or device. This information allows our website or mobile app to retrieve or provide the correct information. Frequently, tracking technologies are used to collect and store your personal preferences, information needed to authenticate you as a valid website visitor, your IP address, your browsing history, and information about your use of a website or mobile app, such as how often you visit, how you arrive at a website or mobile app, and how you interact with our content (for example, what you click on). Each type of tracking technology retrieves information it stores or provides information associated with it. These technologies are not intended to read other information on your device.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Why Does GorillaFunder Use Tracking Technologies?
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Tracking technologies are used primarily to collect and analyze data about your interactions with our website, mobile app, and projects. We use this information to understand project success and user behaviors to improve our website and mobile app and personalize your experience. For example, GorillaFunder uses tracking technologies, and permits Creators to use tracking technologies, to help recognize you as a repeat visitor and to help understand the use of our website or mobile app over time. We might use information obtained from tracking technologies to improve the quality of our Services, enhance your user experience, recognize you or your preferences automatically, and customize your browsing by showing you projects more likely to be relevant to you. Tracking technologies are also used to analyze trends in website traffic and use and measure the effectiveness of marketing and advertising. We may give information from our tracking technologies to our Creators and our vendors, including those whose technologies we use to collect information on our behalf, such as pixels associated with Meta or Tik Tok. When this information is provided to our vendors, it may be subject to their terms. For example, Google provides information that you can read about how Google uses information from the websites or mobile apps that use their services and how you can opt out of Google Analytics.
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Some examples of the types of tracking technologies GorillaFunder uses are below. For convenience, we organize these by general use. These categories are intended to provide information about how we use different types of tracking technologies. Please note that use can vary and some websites you visit may use and categorize tracking technologies differently than we do.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Essential
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Essential tracking technologies are necessary for our website or mobile app to function and make it possible for you to use its features, such as accessing secure areas of the website. These tracking technologies also help with the security of our website or mobile app by facilitating security features and allowing us to detect activity that might violate our rules and Terms of Use. Essential tracking technologies help to protect your account from unauthorized access as well, including by alerting you and us when your account is accessed and allowing us to disable active sessions (for example, when you log out or change your password).
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Functional
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Functional tracking technologies help us enhance the operation of our website or mobile app and personalize your experience. Functional cookies are usually set in response to actions you take to request certain services from us, such as setting your privacy preferences, language choice, account creation, or completing a form like a request for more information or support from our outreach team.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Analytics
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Analytics and statistics tracking technologies, sometimes known as “performance cookies,” allow us to understand how you use our website, such as identifying unique visits, traffic sources, and pages visited, so we can measure and improve the performance of our Service. They help us understand, for example, which pages or content are the most and least popular and how visitors move around the website or mobile app.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Advertising and Marketing
      </Text>
      <Text fontSize={"lg"} mt={4}>
        These tracking technologies may be set by us, by Creators, or by our advertising and marketing partners. They may be used, for example, to personalize the advertisements you see, collect details about our marketing campaigns, or limit how often you see a particular advertisement. They do not typically collect information that directly identifies you, but they may access information that uniquely identifies your browser or device and which may be combined with other information to identify you.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Your Choices
      </Text>
      <Text fontSize={"lg"} mt={4}>
        GorillaFunder provides several ways for you to manage our use of tracking technologies. You can choose not to allow tracking technologies when you visit our website or mobile app; however, blocking some types of these technologies may affect your ability to use our website or mobile app. The choices available to you may differ based on where you live. All visitors to our website and users of our mobile app should have access to at least one of the following options to restrict some uses of tracking technologies.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Web Browsers
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Most web browsers automatically accept cookies, but you can modify your browser settings to decline cookies. To learn how to do this, visit the “Help” menu of your browser or check with your browser provider. You can learn more about cookies by visiting <a href="https://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">AllAboutCookies.org</a>.
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Additionally, you can manage cookies that are set on your browser via your privacy settings and preferences. For example, in most browsers, you can choose to refuse cookies or delete certain cookies. You may also be able to configure your browser to alert you when cookies are being sent. Please note that if you choose to block or delete cookies, you may not be able to use the full range of our Services.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Mobile Devices
      </Text>
      <Text fontSize={"lg"} mt={4}>
        For mobile devices, you may be able to adjust your privacy settings or preferences for your mobile applications to disable tracking or limit tracking within your device settings. Each mobile device is different, so check your device’s instructions for further information.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Do Not Track
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Some web browsers offer a “Do Not Track” feature that allows you to signal your preferences regarding tracking. However, there is currently no standard for how to interpret Do Not Track signals, and GorillaFunder currently does not respond to these signals.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Third Party Tracking Technologies
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Some third-party tracking technologies may be used on our website, mobile app, or in our communications, and this Cookie Policy does not cover third-party practices. When using third-party sites or services, we recommend that you review their privacy policies and practices regarding tracking technologies.
      </Text>

      <Text fontSize={"2xl"} fontWeight={"semibold"} mt={8}>
        Changes to This Cookie Policy
      </Text>
      <Text fontSize={"lg"} mt={4}>
        We may update this Cookie Policy from time to time. We encourage you to review this Cookie Policy periodically for any changes. Your continued use of the Services after the effective date of any changes to this Cookie Policy constitutes your acceptance of such changes.
      </Text>

      <Text fontSize={"lg"} mt={4}>
        If you have any questions or comments about this Cookie Policy or our privacy practices, please contact us at:
      </Text>
      <Text fontSize={"lg"} mt={4}>
        <strong>GorillaFunder, Inc.</strong>
      </Text>
      <Text fontSize={"lg"} mt={4}>
        Attention: Legal Department
      </Text>
      <Text fontSize={"lg"} mt={4}>
      1112 Van Buren Ave,

      </Text>
      <Text fontSize={"lg"} mt={4}>
      Oxford, MS 38655


      </Text>
      <Text fontSize={"lg"} mt={4}>
        <strong>Email:</strong> support@gorillafunder.com
      </Text>
                </Box>
                </Container>
		</VStack>
			<Footer  />
    </>
  )
}

export default CookiePolicy