import { Box, Button, FormControl, FormHelperText, FormLabel, Input, Stack, Textarea, useToast } from '@chakra-ui/react';
import React from 'react';

const MyForm = () => {
  
	const toast = useToast();

  const [form, setForm] = React.useState({
    username: "",
    email: "",
    message: "",
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  
  const submitForm = (e) => {
    e.preventDefault();

    if (!form.username || !form.email || !form.message) {
      toast({
        title: "Form incomplete",
        description: "Please fill out all fields.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      title: "Form submitted",
      description: "We've received your message.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <Box mx="auto" p={4}>
      <Stack spacing={4}>
        {/* Username Field */}
        <FormControl isRequired>
          <FormLabel>Username</FormLabel>
          <Input type="text" placeholder="Enter your username"
            name="username"
            onChange={onChange}
          />
        </FormControl>

        {/* Email Field */}
        <FormControl isRequired>
          <FormLabel>Email address</FormLabel>
          <Input type="email" placeholder="Enter your email"
            name="email"
            onChange={onChange}
          />
          <FormHelperText sx={{
            textAlign: "left",
            color: "#9CA3AF",
          }}>We'll never share your email.</FormHelperText>
        </FormControl>

        {/* Message Textarea */}
        <FormControl isRequired>
          <FormLabel>Message</FormLabel>
          <Textarea placeholder="Enter your message"
            name="message"
            onChange={onChange}
          />
        </FormControl>

        {/* Submit Button */}
        <Button colorScheme="orange" type="submit"
          onClick={submitForm}     
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

export default MyForm;
