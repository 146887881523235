import {
  Box,
  Heading,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import axiosInstance from "../../../../libs/axiosService";
import useGetUser from "../../../../libs/useGetUser";
import { useNavigate, useParams } from "react-router-dom";
import PerksComponent from "../perksComponent";

const FAQ = ({ filmDetails }) => {

  const {
    isOpen: isFaqOpen,
    onOpen: onFaqOpen,
    onClose: onFaqClose,
  } = useDisclosure();
  const [selectedFaq, setSelectedFaq] = React.useState({});
  const { user } = useGetUser();
  const toast = useToast();

  const submitFaqAnswer = async () => {
    try {
      const response = await axiosInstance.post("/faq", {
        project_id: filmDetails._id,
        question: selectedFaq.question,
        user_id: user._id,
      });

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "Question submitted successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onFaqClose();
      }
    } catch (error) {
      console.error("Error submitting faq answer:", error);
    }
  };

  return (
    <>
      <Grid templateColumns={{ base: "1fr", lg: "2fr 1fr" }}>
        <GridItem order={{ base: 2, lg: 1 }} colSpan={{ base: "1fr" }}>
          <Box mb={"100px"} textAlign={"start"}>
            <Heading mt={"30px"} fontSize={"2xl"} fontWeight={"normal"}>
              Frequently Asked Questions
            </Heading>
            <Text mt={"20px"}>
              Looks like there aren't any frequently asked questions yet. Ask
              the project creator directly.
            </Text>
            <Button
              mt={"20px"}
              colorScheme="orange"
              fontSize={"14px"}
              fontWeight={500}
              borderRadius={"5px"}
              onClick={onFaqOpen}
            >
              Ask a question
            </Button>

            {filmDetails?.story?.faqs?.map((faq, index) => (
              <Box mt={"20px"}>
                <Text fontWeight={"600"} fontSize={"14px"}>
                  {" "}
                  {faq.question}
                </Text>
                <Text fontWeight={""} mt={"5px"} fontSize={"14px"} w={"500px"}>
                  {" "}
                  {faq.answer}
                </Text>
              </Box>
            ))}

            <Box mt={"20px"} p={"20px"} border={"1px solid rgba(0,0,0,0.3)"}>
              <Text fontWeight={"600"} fontSize={"24px"}>
                First time on GorillaFunder?
              </Text>

              <Box mt={"20px"}>
                <Text fontWeight={"600"} fontSize={"14px"}>
                  What is GorillaFunder and how does crowdfunding work?
                </Text>
                <Text fontWeight={""} mt={"12px"} fontSize={"14px"}>
                  Yes, Writer/Producer/Director Terry R. Wickham says, "I will
                  be releasing limited special perks and exclusive perks
                  sprinkled over the course of the campaign for the right
                  contribution. Check back frequently to not miss them. I am
                  also willing to listen to any creative idea or perk request
                  you might have to help make Beginning of the End the best
                  movie it can be.
                </Text>
              </Box>
              <Box mt={"20px"}>
                <Text fontWeight={"600"} fontSize={"14px"}>
                  How do I select a perk that interests me on this page?
                </Text>
                <Text fontWeight={""} mt={"12px"} fontSize={"14px"}>
                  On desktop, you’ll see a list with all available perks on the
                  right hand side of the page. Click on the perk you want, then
                  click the "Get This Perk" button to be redirected to the
                  payment page. If you're on a mobile device, scroll up to see
                  all available perks.
                </Text>
              </Box>

              <Box h={"2px"} my={"20px"} bg={"rgba(0,0,0,0.2)"} />

              <Box>
                <Text fontWeight={"600"} fontSize={"24px"}>
                  Already contributed to this project?
                </Text>

                <Box mt={"20px"}>
                  <Text fontWeight={"600"} fontSize={"14px"}>
                    Can I change the shipping address for my perk?
                  </Text>
                  <Text fontWeight={""} mt={"12px"} fontSize={"14px"}>
                    On desktop, you’ll see a list with all available perks on
                    the right hand side of the page. Click on the perk you want,
                    then click the "Get This Perk" button to be redirected to
                    the payment page. If you're on a mobile device, scroll up to
                    see all available perks.
                  </Text>
                </Box>
                <Box mt={"20px"}>
                  <Text fontWeight={"600"} fontSize={"14px"}>
                    Can I change the item, or perk, I selected?
                  </Text>
                  <Text fontWeight={""} mt={"12px"} fontSize={"14px"}>
                    You’ll need to contact the project owner directly to request
                    any change to perks, like color or quantity.
                  </Text>
                </Box>
              </Box>
            </Box>

            {/* Modal for reply to the faq */}
            <Modal isOpen={isFaqOpen} onClose={onFaqClose} size={"xl"}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  Ask a question about {filmDetails?.basic?.title}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    To: {filmDetails?.people?.user?.name || "Project Creator"}
                  </Text>

                  <FormControl mt={4}>
                    <FormLabel>Question</FormLabel>
                    <Textarea
                      placeholder="Enter the answer"
                      value={selectedFaq?.question || ""}
                      onChange={(e) => {
                        setSelectedFaq({
                          ...selectedFaq,
                          question: e.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="orange" mr={3} onClick={submitFaqAnswer}>
                    Submit
                  </Button>
                  <Button onClick={onFaqClose}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </GridItem>

        <GridItem order={{ base: 1, lg: 2 }} colSpan={{ base: "1fr" }}>

          <PerksComponent filmDetails={filmDetails} />

        </GridItem>
      </Grid>
    </>
  );
};

export default FAQ;
