import React from 'react'
import Navbar from '../../Components/Navbar'
import { Box, Container, Divider, Text, VStack } from '@chakra-ui/react'
import Footer from '../../Components/Footer'

import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
} from '@chakra-ui/react'

const TrustSafety = () => {
    return (
        <>

            <VStack spacing={"auto"}>
                <Navbar />
                {/* <HomepageComponent /> */}
                <Box
                    backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
                    backgroundPosition={"center"}
                    backgroundSize={"cover"}
                    backgroundRepeat={"no-repeat"}
                    h={"250px"}
                    w={"full"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text fontSize={{ base: "40px", lg: "96px" }} fontWeight={"500"} color={"white"}>
                        Trust and accountability

                    </Text>
                </Box>
                <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
                    {/* <Grid templateColumns={{ base: "1fr", lg: "2fr 10fr" }} gap={10}> */}
                    <Box textAlign={"start"}>

                        <Text fontSize={"18px"}>GorillaFunder’s mission is to help bring creative projects to life. To that aim, we strive to provide a safe and reliable platform where creators can connect with backers to receive funding, and where backers can discover and support all types of creative work. Being a creator or backer on GorillaFunder requires trust in each other and in our platform, so we’ve outlined the responsibilities and information each of us needs in order to participate.</Text>

                        <Text fontSize={"18px"} my={"20px"}>Here’s an overview:</Text>


                        <UnorderedList listStyleType={"initial"}>
                            <ListItem>It’s GorillaFunder’s responsibility to provide a safe and reliable platform for all of our users.</ListItem>
                            <ListItem>It’s the creator’s responsibility to bring a project to life and deliver the rewards they’ve promised to backers.t</ListItem>
                            <ListItem>Integer molestie lorem at massa</ListItem>
                            <ListItem>Facilisis in pretium nisl aliquet</ListItem>
                        </UnorderedList>

                        <Box>


                            <Text fontSize={"30px"} mt={"20px"} fontWeight={""} color={""}>
                                GorillaFunder’s responsibilities
                            </Text>

                            <Text fontSize={""} mt={"15px"} fontWeight={""}>
                                GorillaFunder is responsible for providing a safe and reliable platform for our users. This includes creating clear rules for creators and backers who use GorillaFunder, and monitoring our site for compliance with those rules. When we learn of users or projects that are violating our Terms of Use, it’s our job to take action by removing content and/or restricting user access.
                            </Text>
                            <Text fontSize={""} mt={"15px"} fontWeight={""}>
                                We do not assess the viability of a creator’s project, monitor how they use funds, or reimburse backers if creators violate their agreement with backers, or are unable to fulfill rewards as planned.
                            </Text>
                            <Text fontSize={""} mt={"15px"} fontWeight={""}>
                                To build trust, we encourage open communication between creators, backers, and GorillaFunder.
                            </Text>

                        </Box>


                        <Divider
                            height={"1px"}
                            bg={"black"}
                            my={"50px"} />


                        <Box>


                            <Text fontSize={"30px"} mt={""} fontWeight={""} color={""}>
                                What GorillaFunder and backers expect from creators
                            </Text>

                            <Text fontSize={""} mt={"15px"} fontWeight={""}>
                                Creators are responsible for following our rules and fulfilling their promises to backers (as outlined in our Terms of Use). This includes providing backers with a high standard of effort, communicating honestly throughout the creative process, using funds appropriately, and protecting backers’ personal information. Creators must also deliver completed rewards as promised, provide backers with a refund (where possible), or give a clear, honest explanation detailing why rewards will not be fulfilled. While it’s difficult to deliver bad news, creators owe this to the people who have shown them support—honesty brings backers along on the creative journey and helps them understand when things don’t go as planned.
                            </Text>
                            <Text fontSize={""} mt={"15px"} fontWeight={""}>
                                Unlike sellers on eCommerce sites, creators on GorillaFunder do not automatically breach their contract with backers if they do not fulfill their rewards or provide users with a full or partial refund. However, if a creator fails to communicate progress updates or delays, does not address backer questions or concerns, or otherwise breaches their agreement with backers, the creator may be subject to legal action from backers.
                            </Text>
                            <Text fontSize={""} mt={"15px"} fontWeight={""}>
                                Creators are also expected to follow our platform rules and use extreme caution when inviting collaborators or other third parties to participate in their projects. We hold creators accountable for the misuse of their accounts, and may suspend or terminate their access.
                            </Text>

                        </Box>




                        <Divider
                            height={"1px"}
                            bg={"black"}
                            my={"50px"} />


                        <Box>


                            <Text fontSize={"30px"} mt={""} fontWeight={""} color={""}>
                                What GorillaFunder and creators expect from backers
                            </Text>

                            <Text fontSize={""} mt={"15px"} fontWeight={""}>
                                Backers are responsible for vetting projects, reporting site violations, and, when they choose to support a project, supporting that project, regardless of the outcome. GorillaFunder provides backers with an opportunity to support creative work at an early stage of development. By doing that, backers are telling the creator they think their work is worth making—and they may even be helping to make culture-shaping, world-changing work a reality. They are also taking a risk, and it’s important they do it knowingly.


                            </Text>
                            <Text fontSize={""} mt={"15px"} fontWeight={""}>
                                Unlike items on an eCommerce site, projects on GorillaFunder are not finished products. It is the responsibility of all backers to thoroughly review the description, risks and challenges, comments, and updates for anything vague or overly ambitious before backing a project. Commenting is only available to backers, but anyone can message a creator in the FAQ section. We encourage backers to search beyond GorillaFunder for more context, and to ask questions of the creator and community. If backers observe or experience any violations of our rules, our terms, or abusive content or behavior, we expect them to report those to us (there’s a link at the bottom of every project page), so that we can take appropriate action.

                            </Text>
                            <Text fontSize={""} mt={"15px"} fontWeight={""}>
                                If a creator is unresponsive or doesn’t fulfill a reward, we encourage backers to message the creator directly and ask for a project update. In all communications, it’s important to remain respectful—making something new is hard, and there’s a person on the other side working to bring their project to life. If a backer feels the creator has breached the agreement between them, the backer may take steps outside of the GorillaFunder platform, including legal action, to enforce the agreement.

                            </Text>

                        </Box>


                    </Box>
                </Container>
            </VStack>
            <Footer />
        </>
    )
}

export default TrustSafety