import React from 'react'
import Navbar from '../../Components/Navbar'
import { Box, Container, Divider, Image, Text, VStack } from '@chakra-ui/react'
import Footer from '../../Components/Footer'

import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
  } from '@chakra-ui/react'

const About = () => {
  return (
    <>

<VStack spacing={"auto"}>
			<Navbar />
				{/* <HomepageComponent /> */}
                <Box
        backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        h={"250px"}
        w={"full"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={{base:"40px",lg:"96px"}} fontWeight={"500"} color={"white"}>
        About Us

        </Text>
      </Box>
                <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
                {/* <Grid templateColumns={{ base: "1fr", lg: "2fr 10fr" }} gap={10}> */}
                <Box textAlign={"start"}>
                <Text fontSize={"lg"}>
                Introducing GorillaFunder – the premier platform built by filmmakers, for filmmakers. GorillaFunder is designed to empower creators by providing a comprehensive toolkit for funding and developing films, all while ensuring you keep more of your hard-earned budget. Unlike other crowdfunding platforms like Kickstarter and Indiegogo, GorillaFunder offers a lower fee structure, allowing filmmakers to allocate more resources directly to their projects. With industry-specific support, access to a network of global distributors, and production tools tailored for independent creators, GorillaFunder is the ultimate resource for bringing your cinematic vision to life. Join a community that understands your passion and is dedicated to making filmmaking more accessible and rewarding.
                </Text>

                <Box  h={"60vh"} my={"40px"} position={"relative"}>
          <Image
            w={"100%"}
            h={"100%"}
            objectFit={"cover"}
            backgroundPosition={"top"}
            src="/images/movies/friends-watching-movie-together-2023-11-27-04-54-24-utc-min.jpg"
            alt="Slide 1"
          />

<Box bg={"orange"} mx={"auto"} w={{base:"100%",lg:"800px"}} display={{base:"none",lg:"block"}} px={{base:"10px",lg:"50px"}} py={"50px"} position={"absolute"} bottom={"-100px"} left={"50%"} 
transform={"translateX(-50%)"} 
>

            
 <Text fontSize={"lg"} >At GorillaFunder, we believe in the power of storytelling. Every film is a journey, and we are here to ensure that your path to production is smooth and successful. Our platform not only provides funding solutions but also fosters a collaborative environment where creators can share ideas, learn from each other, and access valuable resources.

</Text>
</Box>
                
                </Box>
               


                <Box borderRadius={"10px"} bg={"orange"} mx={"auto"} w={{base:"100%",lg:"800px"}} display={{base:"block",lg:"none"}} px={{base:"20px",lg:"50px"}} py={{base:"30px",lg:"50px"}}
>

            
 <Text fontSize={"lg"} >At GorillaFunder, we believe in the power of storytelling. Every film is a journey, and we are here to ensure that your path to production is smooth and successful. Our platform not only provides funding solutions but also fosters a collaborative environment where creators can share ideas, learn from each other, and access valuable resources.

</Text>
</Box>


<Text fontSize={"lg"} mt={{base:"20px",lg:"150px"}}>We understand the unique challenges filmmakers face, from securing funding to navigating the complexities of production. That's why we have curated a suite of tools designed to simplify the funding process. With user-friendly features and dedicated support, GorillaFunder empowers you to focus on what truly matters: your creative vision.

</Text>
<Text fontSize={"lg"} mt={"20px"}>Our mission goes beyond just funding; we strive to create an inclusive community where filmmakers can thrive. Through our network, you’ll find potential collaborators, mentors, and industry veterans who are eager to share their knowledge and experience. We are committed to championing diversity and encouraging underrepresented voices in the film industry, ensuring that all creators have the opportunity to share their stories.



</Text>
                </Box>
                
                </Container>
		</VStack>
			<Footer  />
    </>
  )
}

export default About