import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Progress,
  Text,
  useToast,
} from "@chakra-ui/react";
import LoadingBox from "../Components/shared/Loading";
import axiosInstance from "../libs/axiosService";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import SimpleSlider from "../Components/SimpleSlider/SimpleSlider";

const Home = ({ category }) => {

  const [loading, setLoading] = useState(true);

  const [projects, setProjects] = useState([]);
  const toast = useToast();


  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = category
          ? await axiosInstance.get(`/project-lists?category=${category}`)
          : await axiosInstance.get("/project-lists");
        setProjects(response.data.projects);

        setLoading(false);
        console.log("++++++++++++++", response);
      } catch (error) {
        toast({
          title: "Error",
          description: error.response?.data || "Failed to fetch projects.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });

        setLoading(false);
      }
    };

    fetchProjects();
  }, [toast, category]);

  if (loading) {
    return (
      <>
        <LoadingBox />
      </>
    );
  }

  const navigateToDetails = (id) => {
    if (window.innerWidth < 1024) {
      navigate(`/film/${id}`);
    }
  };

  return (
    <>

      <Box bg={""} position={"relative"} h={"95vh"}>
        {/* <Box w={"100%"} h={"100%"} bg={"rgba(0,0,0,0.6)"} zIndex={"1"} position={"absolute"}></Box> */}
        <Box position={"absolute"} w={"full"} zIndex={"1"}>
          <Navbar />
        </Box>
        {/* <Image w={"100%"} h={"100%"} objectFit={"cover"} src="/images/movies/sliderImageOne.jpeg"/> */}

        <SimpleSlider />

      </Box>



      <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
        <Box textAlign={"start"}>
          <Text fontSize={{ base: "12px", lg: "16px" }} fontWeight={"500"} color={"#F96100"}>
          Lower Fees, Better Film
          </Text>
          <Text fontSize={{ base: "32px", lg: "40px" }} fontWeight={"500"} color={"#1f0733"}>
            Popular Campaigns
          </Text>

          {projects.length > 0 ? (
            <Grid
              templateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
              mt={"20px"}
            >
              {projects?.map((ele, index) => (
                <GridItem colSpan={{ base: "1fr" }}>
                  <Box w={{ base: "100%", lg: "336px" }} h={"560px"} bg={""} onClick={() => {
                    navigateToDetails(ele._id);
                  }}>
                    <Link to={`/film/${ele._id}`}>

                      <Box
                        h={"312px"}
                        borderRadius={"10px"}
                        position={"relative"}
                        className="relative-box"
                      >
                        <Box
                          w={"100%"}
                          h={"100%"}
                          position={"absolute"}
                          top={0}
                          left={0}
                          className="absolute-box"
                        >
                          <Box
                            w={"full"}
                            px={"10px"}
                            className="view-campaign-btn"
                          >
                            <Button w={"full"} size={"lg"} colorScheme="orange">
                              View Campaign
                            </Button>

                          </Box>
                        </Box>
                        <Image
                          borderRadius={"10px"}
                          h={"312px"}
                          w={"100%"}
                          objectFit={"cover"}
                          src={
                            process.env.REACT_APP_API_BASE_URL +
                            ele.basic?.project_image
                          }
                        />
                      </Box>

                      <Box py={"10px"}>
                        <Text
                          lineHeight={"24px"}
                          fontSize={"18px"}
                          fontWeight={"400"}
                          color={"#1f0733"}
                        >
                          {" "}
                          {ele.basic.title}
                        </Text>
                        <Text
                          fontSize={"18px"}
                          fontWeight={"200"}
                          color={"#1f0733"}
                          mt={"15px"}
                        >
                          STARTING AT
                        </Text>
                        <Text
                          fontSize={"24px"}
                          fontWeight={"500"}
                          color={"#1f0733"}
                          mt={""}
                        >
                          {ele.basic.funding_goal_type}
                          {ele.basic?.funding_goal_amount?.toLocaleString()}
                        </Text>
                        <Progress
                          mt={"15px"}
                          colorScheme="orange"
                          value={ele?.funded_percentage?.toFixed(2)}
                          max={100}
                          className="films-grid-progress"
                        />
                        <Flex mt={"5px"} alignItems={"center"} gap={"15px"}>
                          <Text
                            fontSize={"14px"}
                            fontWeight={""}
                            color={"#1f0733"}
                          >
                            ${ele.fund_raised?.toLocaleString()} raised
                          </Text>
                          <Text
                            fontSize={"14px"}
                            fontWeight={""}
                            color={"#1f0733"}
                            mt={""}
                          >
                            {ele.funded_percentage?.toFixed(2)}% funded
                          </Text>
                        </Flex>
                        <Text
                          fontSize={"14px"}
                          fontWeight={""}
                          color={""}
                          mt={"10px"}
                        >
                          {ele.basic.subcategory}
                        </Text>
                      </Box>
                    </Link>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="200px"
              width="100%"
              bg="white.100"
              borderRadius="md"
              m={5}
              boxShadow="md"
            >
              Couldn't find any campaign.
            </Box>
          )}

          <Flex justifyContent={"center"}>
            <NavLink to="/view-campaigns" className="active-link">
              <Text
                textAlign={"center"}
                w={"300px"}
                fontSize={"15px"}
                fontWeight={"600"}
                textTransform={"uppercase"}
                _hover={{ bg: "orange" }}
                border={"2px solid orange"}
                borderRadius={"8px"}
                bg={"transparent"}
                py={"15px"}
                px={"10px"}
              >
                {" "}
                EXPLORE All Campaigns
              </Text>
            </NavLink>
          </Flex>
        </Box>
      </Container>

      {/* purple box */}

      <Box bg={"#000"} py={{ base: "50px", lg: "100px" }} px={{ base: "20px", lg: "220px" }}>
  <Text fontSize={{ base: "32px", lg: "40px" }} color={"white"} fontWeight={"500"}>
    Get the newest campaigns in your inbox
  </Text>
  <Text fontSize={"18px"} color={"white"} mt={"10px"} fontWeight={"500"}>
    Exclusive weekly updates with new products, drops, and special offers.
  </Text>
  <Flex alignItems={"center"} gap={"10px"} justifyContent={"center"} mt={"40px"} flexDir={{ base: "column", lg: "row" }}>
    <Box w={{ base: "100%", lg: "550px" }}>
      <Input
        _placeholder={{ color: "white" }}
        color={"white"}
        bg={"#262626"}
        fontSize={"18px"}
        outline={"none"}
        _peerActive={{ outline: "none" }}
        border={"none"}
        py={"30px"}
        px={"25px"}
        placeholder="Your email address"
      />
    </Box>
    <Button colorScheme="orange" w={{ base: "100%", lg: "150px" }} py={"30px"}>
      SIGN ME UP
    </Button>
  </Flex>
  <Text fontSize={"14px"} color={"white"} mt={"15px"} fontWeight={"500"}>
    By clicking “Sign me up” I have read and agree to GorillaFunder’s Terms of Use and Privacy Policy.
  </Text>

  {/* <Box mt={"40px"} bg={"#262626"} px={{ base: "15px", lg: "120px" }} py={{ base: "50px", lg: "100px" }} color={"white"} borderRadius={"20px"}>
    <Text fontSize={{ base: "36px", lg: "56px" }} fontWeight={"500"}>
      Latest from the Blog
    </Text>

    <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} mt={"60px"} gap={{ base: "20px", lg: "40px" }}>
      <GridItem>
        <Box w={"100%"} h={{ base: "200px", lg: "450px" }} borderRadius={"20px"}>
          <Image w={"100%"} h={"100%"} borderRadius={"20px"} objectFit={"cover"} src="/images/movies/concept-of-cinema-accessories-on-black-smokey-tabl-2023-11-27-05-01-09-utc-min.jpg" />
        </Box>
      </GridItem>
      <GridItem>
        <Flex flexDirection={"column"} gap={"20px"}>
          <Flex flexDir={{ base: "column", md: "row" }} gap={"20px"} alignItems={{ base: "flex-start", md: "center" }} justifyContent={"space-between"}>
            <Box flex={"1"}>
              <Text fontSize={"18px"} fontWeight={"400"} textAlign={"start"}>
                Unboxing: SiiPet: Is it the ultimate pet camera?
              </Text>
              <Text fontSize={"14px"} fontWeight={"400"} textAlign={"start"} mt={"15px"} textTransform={"uppercase"} color={"orange"}>
                Read the review
              </Text>
            </Box>
            <Box w={"130px"} borderRadius={"10px"} h={"112px"} overflow={"hidden"}>
              <Image w={"100%"} h={"100%"} borderRadius={"10px"} objectFit={"cover"} src="/images/movies/fund-2023-11-27-04-55-15-utc-min.jpg" />
            </Box>
          </Flex>

          <Box h={"3px"} bg={"#402A51"} my={"20px"}></Box>

          <Flex flexDir={{ base: "column", md: "row" }} gap={"20px"} alignItems={{ base: "flex-start", md: "center" }} justifyContent={"space-between"}>
            <Box flex={"1"}>
              <Text fontSize={"18px"} fontWeight={"400"} textAlign={"start"}>
                Unboxing: SiiPet: Is it the ultimate pet camera?
              </Text>
              <Text fontSize={"14px"} fontWeight={"400"} textAlign={"start"} mt={"15px"} textTransform={"uppercase"} color={"orange"}>
                Read the review
              </Text>
            </Box>
            <Box w={"130px"} borderRadius={"10px"} h={"112px"} overflow={"hidden"}>
              <Image w={"100%"} h={"100%"} borderRadius={"10px"} objectFit={"cover"} src="/images/movies/concept-of-time-is-money-2023-11-27-05-13-02-utc-min.jpg" />
            </Box>
          </Flex>

          <Box h={"3px"} bg={"#402A51"} my={"20px"}></Box>

          <Flex flexDir={{ base: "column", md: "row" }} gap={"20px"} alignItems={{ base: "flex-start", md: "center" }} justifyContent={"space-between"}>
            <Box flex={"1"}>
              <Text fontSize={"18px"} fontWeight={"400"} textAlign={"start"}>
                Unboxing: SiiPet: Is it the ultimate pet camera?
              </Text>
              <Text fontSize={"14px"} fontWeight={"400"} textAlign={"start"} mt={"15px"} textTransform={"uppercase"} color={"orange"}>
                Learn More
              </Text>
            </Box>
            <Box w={"130px"} borderRadius={"10px"} h={"112px"} overflow={"hidden"}>
              <Image w={"100%"} h={"100%"} borderRadius={"10px"} objectFit={"cover"} src="/images/movies/slapstick-and-money-2023-11-27-05-04-09-utc-min.jpg" />
            </Box>
          </Flex>
        </Flex>
      </GridItem>
    </Grid>

    <Flex mt={"40px"} justifyContent={"center"}>
      <NavLink to="/" className="active-link">
        <Text
          textAlign={"center"}
          w={"300px"}
          fontSize={"15px"}
          fontWeight={"600"}
          textTransform={"uppercase"}
          _hover={{ bg: "orange" }}
          border={"2px solid orange"}
          borderRadius={"8px"}
          bg={"transparent"}
          py={"15px"}
          px={"10px"}
        >
          EXPLORE OUR BLOG
        </Text>
      </NavLink>
    </Flex>
  </Box> */}
</Box>


      <Footer />
    </>
  );
};

export default Home;
